import React from 'react';
import { TextField, Grid, Typography, Paper, Autocomplete, Chip } from '@mui/material';
import { Controller } from 'react-hook-form';

export const FormSection = ({ title, children }) => (
  <Grid item xs={12}>
    <Typography variant="h6">{title}</Typography>
    <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
      <Grid container spacing={2}>
        {children}
      </Grid>
    </Paper>
  </Grid>
);
// Test
export const FormField = ({ name, label, control, errors, ...props }) => (
  <Grid item xs={12} sm={6}>
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          label={label}
          fullWidth
          error={!!errors[name]}
          helperText={errors[name]?.message}
          {...props}
        />
      )}
    />
  </Grid>
);

export const AutocompleteField = ({ name, label, options, control, errors, getOptionLabel, isOptionEqualToValue, onChange, ...props }) => (
  <Grid item xs={12} sm={6}>
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={options}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!errors[name]}
              helperText={errors[name]?.message}
            />
          )}
          onChange={(event, value) => {
            field.onChange(value);
            if (onChange) onChange(event, value);
          }}
          {...props}
        />
      )}
    />
  </Grid>
);


export const TagField = ({ name, label, options, control, errors }) => (
  <Grid item xs={12} sm={6}>
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          multiple
          options={options}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!errors[name]}
              helperText={errors[name]?.message}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={option.name}
                {...getTagProps({ index })}
                key={option.id}
              />
            ))
          }
          onChange={(_, data) => field.onChange(data)}
        />
      )}
    />
  </Grid>
);