import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  CircularProgress
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { inventoryService } from '../services/inventoryService';
import { format } from 'date-fns';

const StockAdjustmentDetailView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [adjustment, setAdjustment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAdjustmentDetail = async () => {
      try {
        setLoading(true);
        const data = await inventoryService.getAdjustmentById(id);
        console.log("Data is", data);
        setAdjustment(data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch adjustment details');
        setLoading(false);
      }
    };

    fetchAdjustmentDetail();
  }, [id]);


  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!adjustment) {
    return <Typography>Adjustment not found.</Typography>;
  }

  return (
    <Paper elevation={3} sx={{ p: 3, my: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" component="h1">
          Stock Adjustment Details
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Adjustment Number:</strong> {adjustment.id}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Adjustment Date:</strong> {format(new Date(adjustment.adjustment_date), 'dd/MM/yyyy')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Warehouse:</strong> {adjustment.warehouse_name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Reason:</strong> {adjustment.reason}
          </Typography>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell align="right">Current Quantity</TableCell>
              <TableCell align="right">Actual Quantity</TableCell>
              <TableCell align="right">Changed Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adjustment.items.map((item) => (
              <TableRow key={item.product}>
                <TableCell>{item.product_name}</TableCell>
                <TableCell align="right">{item.before_quantity}</TableCell>
                <TableCell align="right">{item.after_quantity}</TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default StockAdjustmentDetailView;
