import React from 'react';
import { Button, Grid, Snackbar } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { productService } from '../services/productService';
import { FormSection, FormField } from '../components/FormComponents';

const useProductForm = () => {
  const [skuError, setSkuError] = React.useState('');
  const [existingProducts, setExistingProducts] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });

  const schema = yup.object().shape({
    sku: yup.string().required('SKU is required'),
    name: yup.string().required('Name is required'),
    inventoryAccount: yup.string().required('Inventory account is required'),
    buyPrice: yup.number().positive('Buy price must be positive').required('Buy price is required'),
    buyAccount: yup.string().required('Buy account is required'),
    salePrice: yup.number().positive('Sale price must be positive').required('Sale price is required'),
    saleAccount: yup.string().required('Sale account is required'),
    weight: yup.number().positive('Weight must be positive').nullable(),
    height: yup.number().positive('Height must be positive').nullable(),
    length: yup.number().positive('Length must be positive').nullable(),
    width: yup.number().positive('Width must be positive').nullable(),
    minimumStock: yup.number().integer('Minimum stock must be an integer').min(0, 'Minimum stock must be non-negative').nullable(),
  });

  const { control, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      inventoryAccount: '110200',
      buyAccount: '550000',
      saleAccount: '440000',
    }
  });

  React.useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [fetchedProducts] = await Promise.all([
          productService.fetchAllProductsSkuAndName(),
        ]);
        setExistingProducts(fetchedProducts);
      } catch (error) {
        console.error('Failed to fetch initial data:', error);
        setSnackbar({ open: true, message: 'Failed to fetch initial data', severity: 'error' });
      }
    };
    fetchInitialData();
  }, []);

  const validateSku = (value) => {
    const existingProduct = existingProducts.find(
      product => product.sku.toLowerCase() === value.toLowerCase() || 
                 product.name.toLowerCase() === value.toLowerCase()
    );
    if (existingProduct) {
      setSkuError('SKU or product name already exists');
      return false;
    }
    setSkuError('');
    return true;
  };

  const onSubmit = async (data) => {
    const isValid = validateSku(data.sku);
    if (!isValid) {
      return;
    }
    try {
      const productData = {
        sku: data.sku,
        name: data.name,
        inventory_account: data.inventoryAccount,
        buy_price: data.buyPrice,
        buy_account: data.buyAccount,
        sale_price: data.salePrice,
        sale_account: data.saleAccount,
        details: {
          weight: data.weight,
          height: data.height,
          length: data.length,
          width: data.width,
          minimum_stock: data.minimumStock,
          tag: data.tag,
        }
      };
      await productService.createProduct(productData);
      setSnackbar({ open: true, message: 'Product created successfully', severity: 'success' });
      reset();
    } catch (error) {
      console.error('Failed to create product:', error);
      setSnackbar({ open: true, message: 'Failed to create product', severity: 'error' });
    }
  };

  return { control, handleSubmit, errors, skuError, snackbar, setSnackbar, onSubmit };
};

const ProductForm = () => {
  const { control, handleSubmit, errors, skuError, snackbar, setSnackbar, onSubmit } = useProductForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <FormSection title="Basic Information">
          <FormField name="sku" label="SKU" control={control} errors={{ ...errors, sku: errors.sku || skuError }} />
          <FormField name="name" label="Name" control={control} errors={errors} />
        </FormSection>

        <FormSection title="Financial Information">
          <FormField name="buyPrice" label="Buy Price" control={control} errors={errors} type="number" />
          <FormField name="salePrice" label="Sale Price" control={control} errors={errors} type="number" />
          <FormField name="inventoryAccount" label="Inventory Account" control={control} errors={errors} />
          <FormField name="buyAccount" label="Buy Account" control={control} errors={errors} />
          <FormField name="saleAccount" label="Sale Account" control={control} errors={errors} />
        </FormSection>

        <FormSection title="Product Details">
          <FormField name="weight" label="Weight" control={control} errors={errors} type="number" />
          <FormField name="height" label="Height" control={control} errors={errors} type="number" />
          <FormField name="length" label="Length" control={control} errors={errors} type="number" />
          <FormField name="width" label="Width" control={control} errors={errors} type="number" />
          <FormField name="minimumStock" label="Minimum Stock" control={control} errors={errors} type="number" />
          <FormField name="tag" label="Tag" control={control} errors={errors} />
        </FormSection>

        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Create Product
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </form>
  );
};

export default ProductForm;