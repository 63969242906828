import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ProtectedRoute = ({ element, requiredRole, redirectTo="/unauthorized" }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  // console.log("User is?", user);
  // Convert requiredRoles to an array if it's not already
  const roles = Array.isArray(requiredRole) ? requiredRole : [requiredRole];

  if (!user || !roles.includes(user.role)) {
    // Redirect to the unauthorized page
    return <Navigate to={redirectTo} replace />;
  }

  return element;
};

export default ProtectedRoute;