// src/services/accountService.js

import { axiosInstance, API_ENDPOINTS } from '../api/api';

export const accountService = {
  async fetchAccounts(page = 1, pageSize = 10) {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.accounts, {
        params: {
          page,
          page_size: pageSize,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching accounts:', error);
      throw error;
    }
  },

  async fetchAllAccounts() {
    try {
      let allAccounts = [];
      let nextPage = 1;
      
      while (nextPage) {
        const response = await this.fetchAccounts(nextPage);
        allAccounts = [...allAccounts, ...response.results];
        nextPage = response.next ? nextPage + 1 : null;
      }

      return { results: allAccounts };
    } catch (error) {
      console.error('Error fetching all accounts:', error);
      throw error;
    }
  },

  async addAccount(accountData) {
    try {
      const response = await axiosInstance.post(API_ENDPOINTS.accounts, accountData);
      return response.data;
    } catch (error) {
      console.error('Error adding account:', error);
      throw error;
    }
  },

  async updateAccount(id, accountData) {
    try {
      const response = await axiosInstance.put(`${API_ENDPOINTS.accounts}${id}/`, accountData);
      return response.data;
    } catch (error) {
      console.error('Error updating account:', error);
      throw error;
    }
  },

  async deleteAccount(id) {
    try {
      await axiosInstance.delete(`${API_ENDPOINTS.accounts}${id}/`);
    } catch (error) {
      console.error('Error deleting account:', error);
      throw error;
    }
  },

  async createDeposit(data) {
    try {
      const response = await axiosInstance.post(`${API_ENDPOINTS.accounts}deposit/`, data);
      return response.data;
    } catch (error) {
      console.error('Error creating deposit:', error);
      throw error;
    }
  },

  async createWithdrawal(data) {
    try {
      const response = await axiosInstance.post(`${API_ENDPOINTS.accounts}withdraw/`, data);
      return response.data;
    } catch (error) {
      console.error('Error creating withdrawal:', error);
      throw error;
    }
  },

  async createTransfer(data) {
    try {
      const response = await axiosInstance.post(`${API_ENDPOINTS.accounts}transfer/`, data);
      return response.data;
    } catch (error) {
      console.error('Error creating transfer:', error);
      throw error;
    }
  },

  async fetchTransactions(page = 1, pageSize = 10, transaction_type = 'transfer') {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.transactions, {
        params: {
          page,
          page_size: pageSize,
          transaction_type
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching transactions:', error);
      throw error;
    }
  },

  async fetchAllTransactions() {
    try {
      let allTransactions = [];
      let nextPage = 1;
      
      while (nextPage) {
        const response = await this.fetchTransactions(nextPage);
        allTransactions = [...allTransactions, ...response.results];
        nextPage = response.next ? nextPage + 1 : null;
      }

      return { results: allTransactions };
    } catch (error) {
      console.error('Error fetching all transactions:', error);
      throw error;
    }
  },
  
  async fetchTransactionsByType(transactionType, page = 1, pageSize = 10) {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.transactions, {
        params: {
          transaction_type: transactionType,
          page,
          page_size: pageSize,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching transactions by type:', error);
      throw error;
    }
  },

  async fetchAccountsByCategory(category, page = 1, pageSize = 10) {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.accounts, {
        params: {
          category,
          page,
          page_size: pageSize,
        },
      });
      return response.data.results;
    } catch (error) {
      console.error('Error fetching accounts by category:', error);
      throw error;
    }
  },

};