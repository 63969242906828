import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Autocomplete,
  CircularProgress
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { orderService } from '../services/orderService';
import { customerService } from '../services/customerService';
import { warehouseService } from '../services/warehouseService';
import { productService } from '../services/productService';
import { formatCurrency } from '../utils/currencyUtils';
import { FormField, FormSection } from '../components/FormComponents';
import useSessionStorage from '../hooks/useSessionStorage';
import useDebounce from '../hooks/useDebounce';

const SalesDetailEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const [order, setOrder] = useState(null);
  const [customers, setCustomers] = useSessionStorage('customers', []);
  const [warehouses, setWarehouses] = useSessionStorage('warehouses', []);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, 300);
  const MIN_SEARCH_CHARS = 3;

  const validationSchema = Yup.object().shape({
    order_number: Yup.string().required(intl.formatMessage({ id: 'sales.error.orderNumber.required' })),
    customer_name: Yup.string().required(intl.formatMessage({ id: 'sales.error.customer.required' })),
    order_date: Yup.date().required(intl.formatMessage({ id: 'sales.error.orderDate.required' })),
    warehouse_name: Yup.string().required(intl.formatMessage({ id: 'sales.error.warehouse.required' })),
    status: Yup.string().required(intl.formatMessage({ id: 'sales.error.status.required' })),
    items: Yup.array().of(
      Yup.object().shape({
        quantity: Yup.number().positive(intl.formatMessage({ id: 'sales.error.quantity.positive' })).required(intl.formatMessage({ id: 'sales.error.quantity.required' })),
        price: Yup.number().positive(intl.formatMessage({ id: 'sales.error.price.positive' })).required(intl.formatMessage({ id: 'sales.error.price.required' })),
      })
    ).min(1, intl.formatMessage({ id: 'sales.error.items.min' }))
  });

  const { control, handleSubmit, setValue, watch, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      order_number: '',
      customer_name: '',
      order_date: null,
      warehouse_name: '',
      status: '',
      memo: '',
      items: []
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items"
  });
  useEffect( () => {
    fetchInputProducts();
},[debouncedInputValue]);

const fetchInputProducts = async () => {
  if (debouncedInputValue.length >= MIN_SEARCH_CHARS) {
    try {
      const data = await productService.searchProducts(debouncedInputValue);
      const formattedData = data.map(product => ({
        ...product,
        quantity: 1,
        product: product.id,
        product_sku: product.sku,
        product_name: product.name
      }));
      setProducts(formattedData);

      console.log("Formatted data is: ",formattedData);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }
};

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [orderData] = await Promise.all([
          orderService.fetchOrderDetails(id)
        ]);
        let productsData = orderData.items;
        let warehousesData = warehouses;
        let customersData = customers;
        setOrder(orderData);
        setProducts(productsData);
        console.log('Product data is: ', productsData);
        console.log('Order data is: ', orderData);
        
        setValue('items',productsData);
        if (warehouses.length === 0) {
          warehousesData = await warehouseService.fetchAllWarehouses();
          setWarehouses(warehousesData);
        }
        if (customers.length === 0) {
          customersData = await customerService.fetchAllCustomers();
          setCustomers(customersData);
        }

        Object.keys(orderData).forEach(key => {
          if (key === 'order_date') {
            setValue(key, dayjs(orderData[key]));
          } else if (key === 'items') {
            // const formattedItems = orderData[key].map(item => ({
            //   ...item,
            //   product: productsData.find(p => p.id === item.product) || null
            // }));
            // setValue('items', formattedItems);
            // // setValue('items', productDa);
          } else {
            setValue(key, orderData[key]);
          }
        });
  
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error (e.g., show error message)
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id, setValue]);

  const calculateTotalAmount = (items) => {
    return items.reduce((total, item) => {
      if (item === null) {
        return total; // Skip null items
      }
      const quantity = item.quantity === undefined ? 1 : item.quantity;
      const price = item.price || 0;  // Default to 0 if price is falsy (undefined, null, 0, etc.)
      return total + (quantity * price);
    }, 0);
  };

  const onSubmit = async (data) => {
    try {
      console.log("Masuk ga sih?");
      console.log("Data is ",data);
      const formattedData = {
        ...data,
        order_date: dayjs(data.order_date).format('YYYY-MM-DD'),
        items: data.items.map(item => ({
          ...item,
          product: item.product,
        })),
        total: calculateTotalAmount(data.items)
      };
      console.log("Formatted Data for update is:",formattedData);
      await orderService.updateOrder(id, formattedData);
      navigate(`/sales/${id}`);
    } catch (error) {
      console.error('Error updating order:', error);
      // Handle error (e.g., show error message)
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!order) {
    return <Typography><FormattedMessage id="sales.error.orderNotFound" /></Typography>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="lg">
        <Paper elevation={3} sx={{ p: 3, my: 4 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <Typography variant="h4" component="h1">
              <FormattedMessage id="sales.edit.title" />
            </Typography>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            <FormSection title={<FormattedMessage id="sales.section.orderDetails" />}>
              <FormField
                name="order_number"
                disabled={true}
                label={<FormattedMessage id="sales.field.orderNumber" />}
                control={control}
                errors={errors}
              />
              <Grid item xs={12} sm={6}>
                <Controller
                  name="order_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      label={<FormattedMessage id="sales.field.orderDate" />}
                      disabled={true}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.order_date,
                          helperText: errors.order_date?.message,
                          variant: 'outlined'
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="customer_name"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={customers}
                      disabled={true}
                      getOptionLabel={(option) => option.full_name || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={<FormattedMessage id="sales.field.customer" />}
                          error={!!errors.customer_name}
                          helperText={errors.customer_name?.message}
                        />
                      )}
                      onChange={(_, newValue) => field.onChange(newValue ? newValue.full_name : '')}
                      value={customers.find(c => c.full_name === field.value) || null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="warehouse_name"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      disabled={true}
                      options={warehouses}
                      getOptionLabel={(option) => option.name || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={<FormattedMessage id="sales.field.warehouse" />}
                          error={!!errors.warehouse_name}
                          helperText={errors.warehouse_name?.message}
                        />
                      )}
                      onChange={(_, newValue) => field.onChange(newValue ? newValue.name : '')}
                      value={warehouses.find(w => w.name === field.value) || null}
                    />
                  )}
                />
              </Grid>
              <FormField
                name="status"
                disabled={true}
                label={<FormattedMessage id="sales.field.status" />}
                control={control}
                errors={errors}
              />
              <FormField
                name="memo"
                label={<FormattedMessage id="sales.field.memo" />}
                control={control}
                errors={errors}
                multiline
                rows={3}
              />
            </FormSection>

            <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
              <FormattedMessage id="sales.edit.orderItems" />
            </Typography>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '40%' }}><FormattedMessage id="sales.field.product" /></TableCell>
                    <TableCell style={{ width: '15%' }}><FormattedMessage id="sales.field.quantity" /></TableCell>
                    <TableCell style={{ width: '20%' }}><FormattedMessage id="sales.field.price" /></TableCell>
                    <TableCell style={{ width: '15%' }}><FormattedMessage id="sales.field.total" /></TableCell>
                    <TableCell style={{ width: '10%' }}><FormattedMessage id="sales.field.actions" /></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell style={{ width: '40%' }}>
                      <Controller
                        name={`items.${index}`}
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={products}
                            getOptionLabel={(option) => 
                              option ? `${option.product_sku} - ${option.product_name}` : ''
                            }
                            onInputChange={(_, newInputValue) => {
                              setInputValue(newInputValue);
                            }}
                            isOptionEqualToValue={(option, value) => "A" === "A"}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={!!errors.items?.[index]?.product}
                                helperText={errors.items?.[index]?.product?.message}
                              />
                            )}
                            onChange={(_, newValue) => {
                              field.onChange(newValue);
                              if (newValue) {
                                setValue(`items.${index}.price`, newValue.sale_price || 0);
                                setValue(`items.${index}.quantity`, newValue.quantity || 0);
                              }
                            }}
                            renderOption={(props, option) => (
                              <li {...props}>
                                <Typography variant="body2">
                                  <strong>{option.sku}</strong> - {option.name}
                                </Typography>
                              </li>
                            )}
                            value={field.value || null}
                          />
                        )}
                      />
                      </TableCell>
                      <TableCell style={{ width: '15%' }}>
                        <FormField
                          name={`items.${index}.quantity`}
                          control={control}
                          errors={errors}
                          type="number"
                        />
                      </TableCell>
                      <TableCell style={{ width: '20%' }}>
                        <FormField
                          name={`items.${index}.price`}
                          control={control}
                          errors={errors}
                          type="number"
                        />
                      </TableCell>
                      <TableCell style={{ width: '15%' }}>
                        {formatCurrency(watch(`items.${index}.quantity`) * watch(`items.${index}.price`))}
                      </TableCell>
                      <TableCell style={{ width: '10%' }}>
                        <IconButton onClick={() => remove(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={3} align="right">
                      <Typography variant="subtitle1"><FormattedMessage id="sales.field.totalAmount" /></Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {formatCurrency(calculateTotalAmount(watch('items')))}
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              startIcon={<AddIcon />}
              onClick={() => append({ product: null, quantity: 1, price: 0 })}
              sx={{ mt: 2 }}
            >
              <FormattedMessage id="sales.button.addItem" />
            </Button>

            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button
                startIcon={<CancelIcon />}
                variant="outlined"
                color="secondary"
                onClick={() => navigate(`/sales/${id}`)}
                sx={{ mr: 1 }}
              >
                <FormattedMessage id="common.cancel" />
              </Button>
              <Button
                startIcon={<SaveIcon />}
                variant="contained"
                color="primary"
                type="submit"
              >
                <FormattedMessage id="common.save" />
              </Button>
            </Box>
          </form>
        </Paper>
      </Container>
    </LocalizationProvider>
  );
};

export default SalesDetailEdit;