import React, { createContext, useContext, useState, useEffect } from 'react';
import { userService } from '../services/userService';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const checkAuth = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const userData = await userService.getCurrentUser(token);
          setUser(userData);
          setIsLoggedIn(true);
        }
      } catch (error) {
        console.error("Authentication check failed:", error);
        logout(); 
      } finally {
        setLoading(false);
      }
    };
    checkAuth();
  },[]);

  const login = async (username, password) => {
    try {
      const response = await userService.getToken(username, password);
      const token = response.token;
      
      // Set the token in localStorage
      localStorage.setItem('token', token);
      
      // Use the token directly instead of reading from localStorage
      const userData = await userService.getCurrentUser(token);
      
      setUser(userData);
      setIsLoggedIn(true);
      return token;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };

  const logout = async () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setUser(null);
  };

  const value = {
    isLoggedIn,
    user,
    login,
    logout,
    loading
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthProvider;