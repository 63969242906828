import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Container, Paper, Typography, Button, CircularProgress, Box, Snackbar,
  Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  IconButton, TextField, Autocomplete
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';

import { purchasingService } from '../services/purchasingService';
import { warehouseService } from '../services/warehouseService';
import { productService } from '../services/productService';
import { accountService } from '../services/accountService';
import { formatCurrency } from '../utils/currencyUtils';
import useDebounce from '../hooks/useDebounce';
import useSessionStorage from '../hooks/useSessionStorage';

const schema = yup.object().shape({
  po_number: yup.string().required('Purchase order number is required'),
  warehouse_id: yup.object().nullable().required('Warehouse is required'),
  account_id: yup.object().nullable().required('Account is required'),
  order_date: yup.date().required('Order date is required'),
  items: yup.array().of(
    yup.object().shape({
      product_id: yup.object().nullable().required('Product is required'),
      quantity: yup.number().positive('Quantity must be positive').integer('Quantity must be a whole number').required('Quantity is required'),
      unit_price: yup.number().positive('Unit price must be positive').required('Unit price is required'),
    })
  ).min(1, 'At least one item is required')
});

const PurchaseDetailEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [warehouses, setWarehouses] = useSessionStorage('warehouses',[]);
  const [products, setProducts] = useState([]);
  const [accounts, setAccounts] = useSessionStorage('purchase_accounts',[]);
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue,300);
  const MIN_SEARCH_CHARS = 3;

  const { control, handleSubmit, setValue, watch, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      po_number: '',
      warehouse_id: null,
      account_id: null,
      order_date: null,
      items: []
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items'
  });

  useEffect(() => {
    fetchInputProducts();
  },[debouncedInputValue]);

  const fetchInputProducts = async () => {
    if (debouncedInputValue.length >= MIN_SEARCH_CHARS) {
      try {
        const data = await productService.searchProductsPurchase(debouncedInputValue);
        const formattedData = data.map(product => ({
          ...product,
          product_name: product.name,
        }));
        setProducts(formattedData);
        console.log("Formatted data is: ",formattedData);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let warehousesData = warehouses;
        let accountsData = accounts;
        const purchaseOrderData = await purchasingService.fetchPurchaseOrderDetails(id);
        if (warehouses.length === 0) {
          warehousesData = await warehouseService.fetchAllWarehouses();
          setWarehouses(warehousesData);
        }
        if(accounts.length === 0){
          accountsData = await accountService.fetchAccountsByCategory('Kas & Bank');
          setAccounts(accountsData);
        }
        const productsData = purchaseOrderData.items;
        console.log('Fetched Data:', { purchaseOrderData, warehousesData, productsData, accountsData });
        setProducts(productsData);
        setValue('po_number', purchaseOrderData.po_number);
        
        const selectedWarehouse = warehousesData.find(w => w.id === purchaseOrderData.warehouse);
        setValue('warehouse_id', selectedWarehouse || null);
        
        const selectedAccount = accountsData.find(a => a.id === purchaseOrderData.account);
        setValue('account_id', selectedAccount || null);
        
        setValue('order_date', dayjs(purchaseOrderData.order_date));
        
        const formattedItems = purchaseOrderData.items.map(item => {
          const selectedProduct = productsData.find(p => p.sku === item.sku);
          return {
            product_id: selectedProduct || null,
            quantity: item.quantity,
            unit_price: item.unit_price // Convert the string to a number
          };
        });
        
        console.log('Formatted Items:', formattedItems);
        setValue('items', formattedItems);

      } catch (error) {
        console.error('Error fetching data:', error);
        setSnackbarMessage('Error fetching purchase order details. Please try again.');
        setOpenSnackbar(true);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [id, setValue]);

  const onSubmit = async (data) => {
    console.log('Form Data on Submit:', data);
    try {
      const totalAmount = calculateTotalAmount(true); // Use the raw value
  
      const updatedPurchaseOrder = {
        ...data,
        warehouse: data.warehouse_id?.id,
        account: data.account_id?.id,
        order_date: dayjs(data.order_date).format('YYYY-MM-DD'),
        expected_delivery_date: dayjs(data.order_date).format('YYYY-MM-DD'), // Set to same as order_date
        supplier: 1, // Set default supplier ID to 1
        total: totalAmount, // Set total amount
        items: data.items.map(item => ({
          sku: item.product_id?.sku,
          quantity: item.quantity,
          unit_price: item.unit_price
        }))
      };
      
      console.log('Sending updated purchase order:', updatedPurchaseOrder);
      
      const response = await purchasingService.updatePurchaseOrder(id, updatedPurchaseOrder);
      
      console.log('Update response:', response);
      
      setSnackbarMessage('Purchase order updated successfully');
      setOpenSnackbar(true);
      navigate(`/purchase/${id}`);
    } catch (error) {
      console.error('Error updating purchase order:', error);
      
      let errorMessage = 'Failed to update purchase order. Please try again.';
      
      if (error.response) {
        console.log('Error response:', error.response.data);
        if (typeof error.response.data === 'object') {
          errorMessage = Object.entries(error.response.data)
            .map(([key, value]) => `${key}: ${value}`)
            .join(', ');
        } else if (typeof error.response.data === 'string') {
          errorMessage = error.response.data;
        }
      }
      
      setSnackbarMessage(errorMessage);
      setOpenSnackbar(true);
    }
  };

  const items = watch('items');
  const calculateTotalAmount = (rawValue = false) => {
    const total = items.reduce((sum, item) => sum + (item.quantity * item.unit_price), 0);
    return rawValue ? total : formatCurrency(total);
  };


  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="lg">
        <Paper elevation={3} sx={{ p: 3, my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Edit Purchase Order
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="order_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      label="Date"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.order_date,
                          helperText: errors.order_date?.message
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="po_number"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Purchase Order Number"
                      fullWidth
                      error={!!errors.po_number}
                      helperText={errors.po_number?.message}
                      disabled
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="warehouse_id"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={warehouses}
                      getOptionLabel={(option) => option?.name || ''}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Warehouse"
                          error={!!errors.warehouse_id}
                          helperText={errors.warehouse_id?.message}
                        />
                      )}
                      onChange={(_, data) => field.onChange(data)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="account_id"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={accounts}
                      getOptionLabel={(option) => option?.name || ''}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Account"
                          error={!!errors.account_id}
                          helperText={errors.account_id?.message}
                        />
                      )}
                      onChange={(_, data) => field.onChange(data)}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 4, mb: 2 }}>
              <Typography variant="h6" component="h2">
                Item Details
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => append({ product_id: null, quantity: 1, unit_price: '' })}
              >
                Add Item
              </Button>
            </Box>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="40%">Item SKU</TableCell>
                    <TableCell width="15%">Quantity</TableCell>
                    <TableCell width="15%">Unit Price</TableCell>
                    <TableCell width="20%">Total</TableCell>
                    <TableCell width="10%">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((field, index) => (
                    <TableRow key={field.id}>
                      <TableCell>
                        <Controller
                          name={`items.${index}.product_id`}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              options={products}
                              getOptionLabel={(option) => option ? `${option.sku} - ${option.product_name}` : ''}
                              isOptionEqualToValue={(option, value) => option?.id === value?.id}
                              onInputChange={(_, newInputValue) => {
                                setInputValue(newInputValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!errors.items?.[index]?.product_id}
                                  helperText={errors.items?.[index]?.product_id?.message}
                                  size="small"
                                />
                              )}
                              onChange={(_, value) => {
                                field.onChange(value);
                                if (value) {
                                  setValue(`items.${index}.unit_price`, value.buy_price);
                                  setValue(`items.${index}.quantity`, 1);
                                }
                              }}
                              size="small"
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`items.${index}.quantity`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              error={!!errors.items?.[index]?.quantity}
                              helperText={errors.items?.[index]?.quantity?.message}
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`items.${index}.unit_price`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              error={!!errors.items?.[index]?.unit_price}
                              helperText={errors.items?.[index]?.unit_price?.message}
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        {formatCurrency((items[index]?.quantity || 0) * (items[index]?.unit_price || 0))}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => remove(index)} color="error">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="subtitle1" fontWeight="bold">
                Total Amount: {calculateTotalAmount()}
              </Typography>
              <Box>
                <Button variant="outlined" onClick={() => navigate(`/purchases/${id}`)} sx={{ mr: 1 }}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Update Purchase Order
                </Button>
              </Box>
            </Box>
          </form>
        </Paper>
        
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
        />
      </Container>
    </LocalizationProvider>
  );
};

export default PurchaseDetailEdit;