// src/services/customerService.js

import { axiosInstance, API_ENDPOINTS } from '../api/api';

export const customerService = {
  async fetchCustomers(page = 1, pageSize = 10) {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.customers, {
        params: {
          page,
          page_size: pageSize,
        },
      });
      return {
        results: response.data.results,
        totalCount: response.data.count,
        nextPage: response.data.next,
        previousPage: response.data.previous,
      };
    } catch (error) {
      console.error('Error fetching customers:', error);
      throw error;
    }
  },

  async addCustomer(customerData) {
    try {
      const response = await axiosInstance.post(API_ENDPOINTS.customers, customerData);
      return response.data;
    } catch (error) {
      console.error('Error adding customer:', error);
      throw error;
    }
  },

  async updateCustomer(id, customerData) {
    try {
      const response = await axiosInstance.put(`${API_ENDPOINTS.customers}${id}/`, customerData);
      return response.data;
    } catch (error) {
      console.error('Error updating customer:', error);
      throw error;
    }
  },

  async deleteCustomer(id) {
    try {
      await axiosInstance.delete(`${API_ENDPOINTS.customers}${id}/`);
    } catch (error) {
      console.error('Error deleting customer:', error);
      throw error;
    }
  },

  async fetchAllCustomers() {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.customers, {
        params: {
          page_size: 1000, // Adjust this value based on your needs
        },
      });
      return response.data.results;
    } catch (error) {
      console.error('Error fetching all customers:', error);
      throw error;
    }
  },
};