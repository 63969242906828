import axios from 'axios';
import { API_ENDPOINTS } from '../api/api';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const userService = {
  async getCurrentUser(token) {
    try {
      console.log("APi base url is: ", API_BASE_URL);
      const loginAxiosInstance = axios.create({
        baseURL: API_BASE_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        },
      });
      const response = await loginAxiosInstance.get(`${API_ENDPOINTS.users}get_user_info/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching current user:', error);
      throw error;
    }
  },

  async getToken(username, password) {
    try {
      console.log("APi base url is: ", API_BASE_URL);
      const loginAxiosInstance = axios.create({
        baseURL: API_BASE_URL,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const response = await loginAxiosInstance.post(API_ENDPOINTS.token, {
        username: username,
        password: password
      });
      console.log("Token received:", response.data.token);
      return response.data;
    } catch (error) {
      console.error('Error fetching token:', error);
      throw error;
    }
  }
};