// src/services/commonService.js

import { axiosInstance, API_ENDPOINTS } from '../api/api';

export const commonService = {
  async fetchTransactionNumbers(page = 1, pageSize = 10) {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.transactionNumbers, {
        params: {
          page,
          page_size: pageSize,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching transaction numbers:', error);
      throw error;
    }
  },

  async fetchAllTransactionNumbers() {
    try {
      let allTransactionNumbers = [];
      let nextPage = 1;
      
      while (nextPage) {
        const response = await this.fetchTransactionNumbers(nextPage);
        allTransactionNumbers = [...allTransactionNumbers, ...response.results];
        nextPage = response.next ? nextPage + 1 : null;
      }

      return { results: allTransactionNumbers };
    } catch (error) {
      console.error('Error fetching all transaction numbers:', error);
      throw error;
    }
  },

  async getNextTransactionNumber(transactionType) {
    try {
      const response = await axiosInstance.get(`${API_ENDPOINTS.transactionNumbers}next_number/`, {
        params: { transaction_type: transactionType },
      });
      return response.data.next_number;
    } catch (error) {
      console.error('Error getting next transaction number:', error);
      throw error;
    }
  },

  async incrementTransactionNumber(transactionType) {
    try {
      const response = await axiosInstance.post(`${API_ENDPOINTS.transactionNumbers}increment_number/`, {
        transaction_type: transactionType,
      });
      return response.data.new_number;
    } catch (error) {
      console.error('Error incrementing transaction number:', error);
      throw error;
    }
  },

  async fetchCategoryTags(page = 1, pageSize = 10) {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.categoryTags, {
        params: {
          page,
          page_size: pageSize,
          type: 'PRODUCT_CATEGORY',  // Specify the type for category tags
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching category tags:', error);
      throw error;
    }
  },

  async fetchCountingTags(page = 1, pageSize = 10) {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.categoryTags, {
        params: {
          page,
          page_size: pageSize,
          type: 'COUNTING_TAG',  // Specify the type for counting tags
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching counting tags:', error);
      throw error;
    }
  },

  async fetchAllCategoryTags() {
    try {
      let allCategoryTags = [];
      let nextPage = 1;
      
      while (nextPage) {
        const response = await this.fetchCategoryTags(nextPage);
        allCategoryTags = [...allCategoryTags, ...response.results];
        nextPage = response.next ? nextPage + 1 : null;
      }

      return allCategoryTags;
    } catch (error) {
      console.error('Error fetching all category tags:', error);
      throw error;
    }
  },

  async fetchAllCountingTags() {
    try {
      let allCountingTags = [];
      let nextPage = 1;
      
      while (nextPage) {
        const response = await this.fetchCountingTags(nextPage);
        allCountingTags = [...allCountingTags, ...response.results];
        nextPage = response.next ? nextPage + 1 : null;
      }

      return allCountingTags;
    } catch (error) {
      console.error('Error fetching all counting tags:', error);
      throw error;
    }
  },

  async fetchCategoryTagsByType(categoryType) {
    try {
      const response = await axiosInstance.get(`${API_ENDPOINTS.categoryTags}by_type/`, {
        params: { type: categoryType },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching category tags by type:', error);
      throw error;
    }
  },

  async addCategoryTag(categoryTagData) {
    try {
      const response = await axiosInstance.post(API_ENDPOINTS.categoryTags, categoryTagData);
      return response.data;
    } catch (error) {
      console.error('Error adding category tag:', error);
      throw error;
    }
  },

  async updateCategoryTag(id, categoryTagData) {
    try {
      const response = await axiosInstance.put(`${API_ENDPOINTS.categoryTags}${id}/`, categoryTagData);
      return response.data;
    } catch (error) {
      console.error('Error updating category tag:', error);
      throw error;
    }
  },

  async deleteCategoryTag(id) {
    try {
      await axiosInstance.delete(`${API_ENDPOINTS.categoryTags}${id}/`);
    } catch (error) {
      console.error('Error deleting category tag:', error);
      throw error;
    }
  },
};