export default {
    'sales.dashboard.title': 'Sales Dashboard',
    'sales.totalOrders.title': 'Total Orders',
    'sales.totalRevenue.title': 'Total Revenue',
    'sales.averageOrderValue.title': 'Average Order Value',
    'sales.button.addNew': 'Add New Sale',
    'sales.button.export': 'Export Sales',
    'sales.button.import': 'Import Sales',
    'sales.button.delete': 'Delete',
    'sales.grid.date': 'Date',
    'sales.grid.customer': 'Customer',
    'sales.grid.orderNumber': 'Order #',
    'sales.grid.total': 'Total',
    'sales.grid.tag': 'Tag',
    'sales.grid.status': 'Status',
    'sales.grid.actions': 'Actions',
    'sales.button.view': 'View',
    'sales.confirm.delete': 'Are you sure you want to delete sale {orderNumber}?',
    'sales.edit.title': 'Edit Sales Order',
    'sales.edit.orderDetails': 'Order Details',
    'sales.edit.orderItems': 'Order Items',
    'sales.field.orderNumber': 'Order Number',
    'sales.field.orderDate': 'Order Date',
    'sales.field.customer': 'Customer',
    'sales.field.warehouse': 'Warehouse',
    'sales.field.status': 'Status',
    'sales.field.memo': 'Memo',
    'sales.field.product': 'Product',
    'sales.field.quantity': 'Quantity',
    'sales.field.price': 'Price',
    'sales.field.total': 'Total',
    'sales.field.actions': 'Actions',
    'sales.button.addItem': 'Add Item',
    
    'sales.error.orderNumber.required': 'Order number is required',
    'sales.error.customer.required': 'Customer is required',
    'sales.error.orderDate.required': 'Order date is required',
    'sales.error.warehouse.required': 'Warehouse is required',
    'sales.error.status.required': 'Status is required',
    'sales.error.product.required': 'Product is required',
    'sales.error.productName.required': 'Product name is required',
    'sales.error.quantity.positive': 'Quantity must be positive',
    'sales.error.quantity.required': 'Quantity is required',
    'sales.error.price.positive': 'Price must be positive',
    'sales.error.price.required': 'Price is required',
    'sales.error.items.min': 'At least one item is required',
    'sales.error.orderNotFound': 'Order not found',
    'sales.success.orderUpdated': 'Order updated successfully',
    'sales.error.updateFailed': 'Failed to update order. Please try again.',
    'sales.section.orderDetails': 'Order Details',
    'sales.field.totalAmount': 'Total',
  };