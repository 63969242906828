export default {
    "contact.dashboard.title": "Contact Dashboard",
    "contact.new.customer": "New Customer",
    "contact.new.supplier": "New Supplier",
    "contact.tab.customers": "Customers",
    "contact.tab.suppliers": "Suppliers",
    "contact.search": "Search contacts...",
    "contact.name": "Contact Name",
    "contact.address": "Address",
    "contact.phone": "Phone Number",
    "contact.actions": "Actions",
    "contact.view": "View",
    "contact.edit": "Edit",
    "contact.delete": "Delete"
}