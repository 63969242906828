export default {
    'account.dashboard.title': 'Accounting Dashboard',
    'account.new.button': 'New Account',
    'account.deposit.button': 'Deposit',
    'account.withdrawal.button': 'Withdrawal',
    'account.transfer.button': 'Transfer',
    'account.inventoryAmount.title': 'Inventory Amount',
    'account.inventoryAmount.subtitle': 'Total value of current inventory',
    'account.cashAmount.title': 'Cash Amount',
    'account.cashAmount.subtitle': 'Available cash balance',
    'account.futureExpense.title': "This Month's Future Expense",
    'account.futureExpense.subtitle': 'Projected expenses for the month',
    'account.incomingProduct.title': 'Incoming Product Amount',
    'account.incomingProduct.subtitle': 'Value of incoming inventory',
    'account.search.label': 'Search accounts',
    'account.tab.accounts': 'Accounts',
    'account.tab.transactions': 'Transactions',
  };