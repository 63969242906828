import common from './common';
import account from './account';
import sales from './sales';
import purchase from './purchase';
import contact from './contact';
import warehouse from './warehouse';
import stock from './stock';

export default {
  ...common,
  ...account,
  ...sales,
  ...purchase,
  ...warehouse,
  ...contact,
  ...stock
};