import React, { useState, useEffect, useMemo } from "react";
import { 
    Box, 
    Typography, 
    Button, 
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { purchasingService } from '../services/purchasingService';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import useDebounce from '../hooks/useDebounce';
import { useAuth } from "../contexts/AuthContext";

const PurchaseDashboard = () => {
    const navigate = useNavigate();
    const intl = useIntl();
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('2024-09');
    const { user } = useAuth();
    const debouncedSearchTerm = useDebounce(searchTerm, 300);

    const MIN_SEARCH_CHARS = 3;

    useEffect(() => {
        fetchSearchOrders();
      }, [debouncedSearchTerm]);
    
      const fetchSearchOrders = async () => {
        if (debouncedSearchTerm.length >= MIN_SEARCH_CHARS) {
          try {
            setIsLoading(true);
            const data = await purchasingService.searchPO(debouncedSearchTerm);
            // console.log("Data is ",data);
            setPurchaseOrders(data.results);
            setIsLoading(false);
          } catch (error) {
            console.error('Error fetching po:', error);
            setIsLoading(false);
          }
    }};

    const fetchPurchaseOrders = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const today = dayjs().format('YYYY-MM-DD');
            console.log('Fetching purchase orders for date:', today);
            const { results } = await purchasingService.searchPO(today);
            console.log('Received purchase orders:', results);
            
            if (results.length === 0) {
                console.log('No purchase orders found for today');
            }
            
            setPurchaseOrders(results);
        } catch (error) {
            console.error("Error fetching purchase orders:", error);
            setError("Failed to fetch purchase orders. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPurchaseOrders();
    }, []);

    const handleView = (row) => {
        navigate(`/purchase/${row.id}`);
    };

    const handleDeleteClick = (id) => {
        setSelectedOrderId(id);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await purchasingService.deletePurchaseOrder(selectedOrderId);
            setDeleteDialogOpen(false);
            fetchPurchaseOrders();
        } catch (error) {
            console.error("Error deleting purchase order:", error);
            setError("Failed to delete purchase order. Please try again later.");
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    }

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setSelectedOrderId(null);
    };

    const columns = useMemo(() => [
        {field: 'order_date', headerName: intl.formatMessage({id: "purchase.grid.date"}), width: 150},
        {field: 'po_number', headerName: intl.formatMessage({id: "purchase.grid.orderNumber"}), width: 150},
        {field: 'total', headerName: intl.formatMessage({id: "purchase.grid.total"}), width: 150},
        {field: 'status', headerName: intl.formatMessage({id: "purchase.grid.status"}), width: 150},
        {
            field: 'actions',
            headerName: intl.formatMessage({id: "common.actions"}),
            width: 200,
            renderCell: (params) => (
                <Box>
                    <Button variant="outlined" onClick={() => handleView(params.row)} sx={{ mr: 1 }}>
                        <FormattedMessage id="common.view" />
                    </Button>
                    <Button variant="outlined" onClick={() => handleDeleteClick(params.row.id)}>
                        <FormattedMessage id="common.delete" />
                    </Button>
                </Box>
            ),
        },
    ], [intl]);

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h4" gutterBottom>
                <FormattedMessage id="purchase.dashboard.title" />
            </Typography>
            <Box display="flex" gap={2} mb={2}>
                <Button variant="outlined" onClick={() => navigate('/purchase/new')}>
                    <FormattedMessage id="purchase.button.addNew" />
                </Button>
                {/* <Button variant="outlined">
                    <FormattedMessage id="purchase.button.export" />
                </Button>
                <Button variant="outlined">
                    <FormattedMessage id="purchase.button.import" />
                </Button> */}
            </Box>
            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}
            {purchaseOrders.length === 0 && !isLoading && !error && (
                <Typography variant="body1" color="text.secondary">
                    No purchase orders found for today.
                </Typography>
            )}
            <TextField
                label="Search PO"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchTerm}
                onChange={handleSearchChange}
            />

            <DataGrid 
                rows={purchaseOrders}
                columns={columns}
                loading={isLoading}
            />
            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Deletion"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this purchase order? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default PurchaseDashboard;