import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { accountService } from '../../services/accountService';

const TransferForm = ({ open, handleClose, accounts, onTransferCreated }) => {
  const [formData, setFormData] = useState({
    fromAccount: '',
    toAccount: '',
    amount: '',
    description: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await accountService.createTransfer(formData);
      onTransferCreated();
      handleClose();
    } catch (error) {
      console.error('Error creating transfer:', error);
    }
  };

  // Filter accounts to only include those with category "Kas & Bank"
  const filteredAccounts = accounts.filter(account => 
    account.category === "Kas & Bank" || account.category === "Kas Online" || account.category === "Beban"
  );

  // const filteredAccounts = accounts.filter(account => 
  //   account.category === "Kas & Bank" || account.category === "Kas Online"
  // );

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create Transfer</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>From Account</InputLabel>
          <Select name="fromAccount" value={formData.fromAccount} onChange={handleChange}>
            {filteredAccounts.map((account) => (
              <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>To Account</InputLabel>
          <Select name="toAccount" value={formData.toAccount} onChange={handleChange}>
            {filteredAccounts.map((account) => (
              <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          margin="normal"
          name="amount"
          label="Amount"
          type="number"
          value={formData.amount}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          name="description"
          label="Description"
          multiline
          rows={2}
          value={formData.description}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">Create Transfer</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferForm;