// src/services/productService.js

import { axiosInstance, API_ENDPOINTS } from '../api/api';

export const productService = {
  async fetchAllProducts() {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.products, {
        params: {
          page_size: 1000, // Set a large number to get all products
        },
      });
      return response.data.results; // Assuming the API returns all results in a 'results' field
    } catch (error) {
      console.error('Error fetching products:', error);
      throw error;
    }
  },
  
  async fetchProduct(id){
    try {
      const response = await axiosInstance.get(`${API_ENDPOINTS.products}${id}/`);
      const product_result = {
        id: response.data.id,
        sku: response.data.sku,
        name: response.data.name,
        quantity: response.data.stock_quantity,
        sale_price: response.data.sale_price,
        note: response.data.note,
      };
      
      return product_result;
    } catch (error) {
      console.error('Error searching products:', error);
      throw error;
    }
  },

  async searchProducts(searchTerm, page = 1, pageSize = 100) {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.products, {
        params: {
          search: searchTerm,
          fields: 'sku,name,sale_price',
          page: page,
          page_size: pageSize,
        },
      });
  
      const searchResults = response.data.results.map(product => ({
        id: product.id,
        sku: product.sku,
        name: product.name,
        quantity: product.stock_quantity,
        sale_price: product.sale_price,
        note: product.note,
      }));
      console.log(searchResults);
      return searchResults;
    } catch (error) {
      console.error('Error searching products:', error);
      throw error;
    }
  },

  async searchProductsPurchase(searchTerm, page = 1, pageSize = 100) {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.products, {
        params: {
          search: searchTerm,
          fields: 'sku,name,buy_price', // Request only SKU, name, and sale_price fields
          page: page,
          page_size: pageSize,
        },
      });
  
      const searchResults = response.data.results.map(product => ({
        id: product.id,
        sku: product.sku,
        name: product.name,
        quantity: product.stock_quantity,
        buy_price: product.buy_price,
      }));
      console.log(searchResults);
      return searchResults;
    } catch (error) {
      console.error('Error searching products:', error);
      throw error;
    }
  },

  async searchProductsInventory(searchTerm, page = 1, pageSize = 100) {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.products, {
        params: {
          search: searchTerm,
          fields: 'sku,name', // Request only SKU, name, and sale_price fields
          page: page,
          page_size: pageSize,
        },
      });
  
      const searchResults = response.data.results.map(product => ({
        id: product.id,
        sku: product.sku,
        name: product.name,
        quantity: product.stock_quantity
      }));
      // console.log(searchResults);
      return searchResults;
    } catch (error) {
      console.error('Error searching products:', error);
      throw error;
    }
  },

  async fetchAllProductsForOrderForm() {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.products, {
        params: {
          fields: 'sku,name,sale_price,stock_quantity', // Request only SKU and name fields
          page_size: 1000, // Set a large number to get all products
        },
      });
      const filteredProducts = response.data.results.map(product => ({
        product_id: product.id,
        sku: product.sku,
        name: product.name,
        sale_price: product.sale_price
      }));
      
      return filteredProducts;
    } catch (error) {
      console.error('Error fetching products SKU and name:', error);
      throw error;
    }
  },

  async deleteProduct(id) {
    try {
      await axiosInstance.delete(`${API_ENDPOINTS.products}${id}/`);
    } catch (error) {
      console.error('Error deleting product:', error);
      throw error;
    }
  },

  async createProduct(productData) {
    try {
      const response = await axiosInstance.post(API_ENDPOINTS.products, productData);
      return response.data; 
    } catch (error) {
      console.error('Error creating product:', error);
      throw error;
    }
  },

  async fetchProductSalesData(dateFrom, dateTo, customer_id) {
    try {
      // Convert customer_id array to a comma-separated string
      const customerIdString = customer_id.join(',');
      const response = await axiosInstance.get(`${API_ENDPOINTS.products}sales_data/`, {
        params: {
          date_from: dateFrom,
          date_to: dateTo,
          customer: customerIdString
        }
      });
      return response.data.map(item => ({
        sku: item.product__sku,
        name: item.product__name,
        quantity: item.quantity,
        revenue: item.revenue,
        cost: item.cost,
        total_profit: item.revenue - item.cost,
        margin: ((item.revenue - item.cost) / item.revenue) * 100
      }));
    } catch (error) {
      console.error('Error fetching product sales data:', error);
      throw error;
    }
  },

  async fetchRevenueData(dateFrom, dateTo, customer_id) {
    try {
      const response = await axiosInstance.get(`${API_ENDPOINTS.products}revenue_data/`, {
        params: {
          date_from: dateFrom,
          date_to: dateTo,
          customer_id: customer_id
        }
      });
      return response.data.total_revenue;
    } catch (error) {
      console.error('Error fetching revenue data:', error);
      throw error;
    }
  },

  
};