import { axiosInstance, API_ENDPOINTS } from '../api/api';

export const inventoryService = {
  createTransfer: async (transferData) => {
    try {
      const response = await axiosInstance.post(API_ENDPOINTS.stocktransfer, transferData);
      return response.data;
    } catch (error) {
      console.error('Error creating stock transfer:', error);
      throw error;
    }
  },

  getTransfers: async (params = {}) => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.stocktransfer);
      return response.data.results;
    } catch (error) {
      console.error('Error fetching stock transfers:', error);
      throw error;
    }
  },


  getTransferById: async (id) => {
    try {
      const response = await axiosInstance.get(`${API_ENDPOINTS.stocktransfer}${id}/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching stock transfer:', error);
      throw error;
    }
  },

  updateTransfer: async (id, updateData) => {
    try {
      console.log("Update data is: ", updateData);
      const response = await axiosInstance.put(`${API_ENDPOINTS.stocktransfer}${id}/`, updateData);
      return response.data;
    } catch (error) {
      console.error('Error updating stock transfer:', error);
      throw error;
    }
  },

  deleteTransfer: async (id) => {
    try {
      const response = await axiosInstance.delete(`${API_ENDPOINTS.stocktransfer}${id}/`);
      return response.data;
    } catch (error) {
      console.error('Error deleting stock transfer:', error);
      throw error;
    }
  },

  // Stock Adjustment Functions
  createAdjustment: async (adjustmentData) => {
    try {
      const response = await axiosInstance.post(API_ENDPOINTS.stockadjustment, adjustmentData);
      return response.data;
    } catch (error) {
      console.error('Error creating stock adjustment:', error);
      throw error;
    }
  },

  getAdjustments: async (params = {}) => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.stockadjustment, { params });
      return response.data.results;
    } catch (error) {
      console.error('Error fetching stock adjustments:', error);
      throw error;
    }
  },

  getAdjustmentById: async (id) => {
    try {
      const response = await axiosInstance.get(`${API_ENDPOINTS.stockadjustment}${id}/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching stock adjustment:', error);
      throw error;
    }
  },

  updateAdjustment: async (id, updateData) => {
    try {
      const response = await axiosInstance.put(`${API_ENDPOINTS.stockadjustment}/${id}`, updateData);
      return response.data;
    } catch (error) {
      console.error('Error updating stock adjustment:', error);
      throw error;
    }
  },
  deleteAdjustment: async (id) => {
    try {
      const response = await axiosInstance.delete(`${API_ENDPOINTS.stockadjustment}/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting stock adjustment:', error);
      throw error;
    }
  },
  fetchInventoryByWarehouse: async (warehouse_id) => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.inventory,{
        params: {
          warehouse_id,
          page_size: 1000,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching inventory:', error);
      throw error;
    }
  },

  fetchInventoryByProduct: async (product_id) => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.inventory,{
        params: {
          product_id,
          page_size: 1000, 
        },
      });
      return response.data.results;
    } catch (error) {
      console.error('Error fetching inventory:', error);
      throw error;
    }
  },

  fetchStockMovement: async (product) => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.stockmovements, {
        params: {
          product,
          page_size: 20,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching stock movement:', error);
      throw error;
    }
  },
};