import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Box,
  Typography,
  Grid,
  IconButton,
  Autocomplete,
  TextField,
  Snackbar
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import { warehouseService } from '../services/warehouseService';
import { productService } from '../services/productService';
import { purchasingService } from '../services/purchasingService';
import { commonService } from '../services/commonService';
import { accountService } from '../services/accountService';
import { formatCurrency } from '../utils/currencyUtils';
import useDebounce from '../hooks/useDebounce';
import useSessionStorage from '../hooks/useSessionStorage';

const schema = yup.object().shape({
  po_number: yup.string().required('Purchase order number is required'),
  warehouse_id: yup.object().nullable().required('Warehouse is required'),
  account_id: yup.object().nullable().required('Account is required'),
  order_date: yup.date().required('Order date is required'),
  // items: yup.array().of(
  //   yup.object().shape({
  //     quantity: yup.number().positive('Quantity must be positive').integer('Quantity must be a whole number').required('Quantity is required'),
  //     unit_price: yup.number().positive('Unit price must be positive').required('Unit price is required'),
  //   })
  // ).min(1, 'At least one item is required')
});

const PurchaseForm = () => {
  const navigate = useNavigate();

  const [warehouses, setWarehouses] = useSessionStorage('warehouses',[]);
  const [products, setProducts] = useSessionStorage('purchase_products',[]);
  const [accounts, setAccounts] = useSessionStorage('purchase_accounts',[]);
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const debouncedInputValue = useDebounce(inputValue,500);

  const MIN_SEARCH_CHARS = 3;

  const { control, handleSubmit, setValue, watch, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      po_number: '',
      warehouse_id: null,
      account_id: null,
      order_date: dayjs(),
      items: []
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items'
  });

  useEffect(() => {
      fetchInputProducts();
  },[debouncedInputValue]);

  const fetchInputProducts = async () => {
    if (debouncedInputValue.length >= MIN_SEARCH_CHARS) {
      try {
        const data = await productService.searchProductsPurchase(debouncedInputValue);
        const formattedData = data.map(product => ({
          ...product,
          formattedSalePrice: formatCurrency(product.sale_price),
        }));
        setProducts(formattedData);
        console.log("Formatted data is: ",formattedData);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const nextNumber = await commonService.getNextTransactionNumber('PURCHASE');
        let warehousesData = warehouses;
        let accountsData = accounts;
        let productsData = products;
        if (warehouses.length === 0) {
          warehousesData = await warehouseService.fetchAllWarehouses();
          setWarehouses(warehousesData);
        }
        if(accounts.length === 0){
          accountsData = await accountService.fetchAccountsByCategory('Kas & Bank');
          setAccounts(accountsData);
        }
        if(products.length === 0){
          productsData = await productService.fetchAllProducts();
          setProducts(productsData);
        }
        setValue('po_number', nextNumber);
      } catch (error) {
        console.error('Error fetching data:', error);
        setSnackbarMessage('Error fetching initial data. Please try again.');
        setOpenSnackbar(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [setValue]);

  const onSubmit = async (data) => {
    try {
      const newPurchaseNumber = await commonService.incrementTransactionNumber('PURCHASE');
      console.log("Data is:",data);
      const purchaseOrderData = {
        po_number: newPurchaseNumber,
        warehouse: data.warehouse_id.id,
        account: data.account_id.id,
        supplier: '1', // Fixed supplier ID
        order_date: dayjs(data.order_date).format('YYYY-MM-DD'),
        expected_delivery_date: dayjs(data.order_date).format('YYYY-MM-DD'), // Set to the same as order_date
        status: 'complete', // Fixed status
        total: calculateTotalAmount(true),
        items: data.items.map(({ id, quantity, unit_price }) => ({
          sku: id.sku,
          quantity,
          unit_price
        })),
      };
      console.log("Formatted data is:",purchaseOrderData);
      await purchasingService.createPurchaseOrder(purchaseOrderData);
      setSnackbarMessage('Purchase order created successfully');
      setOpenSnackbar(true);
      navigate(`/purchase`);
    } catch (error) {
      console.error('Error submitting form:', error);
      setSnackbarMessage('Failed to create purchase order. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const items = watch('items');
  const calculateTotalAmount = (rawValue = false) => {
    const total = items.reduce((sum, item) => sum + (item.quantity * item.unit_price), 0);
    return rawValue ? total : formatCurrency(total);
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="lg">
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Purchase Order Form
          </Typography>
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="order_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        label="Date"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!errors.order_date}
                            helperText={errors.order_date?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="po_number"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Purchase Order Number"
                        fullWidth
                        error={!!errors.po_number}
                        helperText={errors.po_number?.message}
                        disabled
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Controller
                    name="warehouse_id"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={warehouses}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Warehouse"
                            error={!!errors.warehouse_id}
                            helperText={errors.warehouse_id?.message}
                          />
                        )}
                        onChange={(_, value) => field.onChange(value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="account_id"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={accounts}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Account"
                            error={!!errors.account_id}
                            helperText={errors.account_id?.message}
                          />
                        )}
                        onChange={(_, value) => field.onChange(value)}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Paper>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" component="h2">
                Item Details
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => append({ product_id: null, quantity: 1, unit_price: '' })}
              >
                Add Item
              </Button>
            </Box>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="40%">Item SKU</TableCell>
                    <TableCell width="15%">Quantity</TableCell>
                    <TableCell width="15%">Unit Price</TableCell>
                    <TableCell width="20%">Total</TableCell>
                    <TableCell width="10%">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((field, index) => (
                    <TableRow key={field.id}>
                      <TableCell>
                        <Controller
                          name={`items.${index}.id`}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              options={products}
                              getOptionLabel={(option) => option ? `${option.sku} - ${option.name}` : ''}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              onInputChange={(_, newInputValue) => {
                                setInputValue(newInputValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!errors.items?.[index]?.product}
                                  helperText={errors.items?.[index]?.product?.message}
                                />
                              )}
                              onChange={(_, value) => {
                                field.onChange(value);
                                if (value) {
                                  setValue(`items.${index}.unit_price`, value.buy_price);
                                  setValue(`items.${index}.quantity`, 1);
                                  setValue(`items.${index}.product_id`, value.product_id);
                                }
                              }}
                              value={field.value || null}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`items.${index}.quantity`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              size="small"
                              fullWidth
                              error={!!errors.items?.[index]?.quantity}
                              helperText={errors.items?.[index]?.quantity?.message}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`items.${index}.unit_price`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              size="small"
                              fullWidth
                              error={!!errors.items?.[index]?.unit_price}
                              helperText={errors.items?.[index]?.unit_price?.message}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        {formatCurrency((items[index]?.quantity || 0) * (items[index]?.unit_price || 0))}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => remove(index)} color="error">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="subtitle1" fontWeight="bold">
                Total Amount: {calculateTotalAmount()}
              </Typography>
              <Button variant="contained" color="primary" type="submit" onClick={() => console.log("Submit button clicked")}>
                Submit Purchase Order
              </Button>
            </Box>
          </form>
        </Box>
        
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
        />
      </Container>
    </LocalizationProvider>
  );
};

export default PurchaseForm;