import React, { useState } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Paper,
  Grid,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`settings-tabpanel-${index}`}
    aria-labelledby={`settings-tab-${index}`}
    {...other}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const Settings = () => {
  const [tabValue, setTabValue] = useState(0);
  const [settings, setSettings] = useState({
    // Basic Settings
    companyName: '',
    email: '',
    phone: '',
    address: '',
    bankAccount: '',
    bankAccountName: '',
    
    // Authorization Settings
    roles: {
      administrator: true,
      finance: true,
      supervisor: true,
    },
    
    // Accounting Account Settings
    defaultCurrency: 'USD',
    fiscalYearStart: '',
    inventoryAccount: '',
    accountsPayable: '',
    salesAccount: '',
    accountsReceivable: '',
    cogsAccount: '',
    
    // PDF Report Settings
    logo: null,
    primaryColor: '#000000',
    fontSize: 12,

    // Language Settings
    language: 'en',
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleRoleChange = (role) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      roles: {
        ...prevSettings.roles,
        [role]: !prevSettings.roles[role],
      },
    }));
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    setSettings(prevSettings => ({
      ...prevSettings,
      logo: file,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Settings submitted:', settings);
    // Here you would typically send the settings to your backend
  };

  return (
    <Paper elevation={3} sx={{ margin: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="settings tabs">
          <Tab label="Basic Settings" />
          <Tab label="Authorization" />
          <Tab label="Accounting" />
          <Tab label="PDF Reports" />
          <Tab label="Language" />
        </Tabs>
      </Box>

      <form onSubmit={handleSubmit}>
        <TabPanel value={tabValue} index={0}>
          <Typography variant="h6" gutterBottom>Basic Settings</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Company Name"
                name="companyName"
                value={settings.companyName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={settings.email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                value={settings.phone}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                multiline
                rows={3}
                value={settings.address}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Bank Account"
                name="bankAccount"
                value={settings.bankAccount}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Bank Account Name"
                name="bankAccountName"
                value={settings.bankAccountName}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Typography variant="h6" gutterBottom>Authorization Settings</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.roles.administrator}
                    onChange={() => handleRoleChange('administrator')}
                    name="administrator"
                  />
                }
                label="Administrator"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.roles.finance}
                    onChange={() => handleRoleChange('finance')}
                    name="finance"
                  />
                }
                label="Finance"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.roles.supervisor}
                    onChange={() => handleRoleChange('supervisor')}
                    name="supervisor"
                  />
                }
                label="Supervisor"
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <Typography variant="h6" gutterBottom>Accounting Account Settings</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Default Currency"
                name="defaultCurrency"
                value={settings.defaultCurrency}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Fiscal Year Start"
                name="fiscalYearStart"
                type="date"
                value={settings.fiscalYearStart}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Inventory Account"
                name="inventoryAccount"
                value={settings.inventoryAccount}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Accounts Payable"
                name="accountsPayable"
                value={settings.accountsPayable}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Sales Account"
                name="salesAccount"
                value={settings.salesAccount}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Accounts Receivable"
                name="accountsReceivable"
                value={settings.accountsReceivable}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="COGS Account"
                name="cogsAccount"
                value={settings.cogsAccount}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={3}>
          <Typography variant="h6" gutterBottom>PDF Report Settings</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={handleLogoChange}
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                  Upload Logo
                </Button>
              </label>
              {settings.logo && <Typography variant="body2">{settings.logo.name}</Typography>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Primary Color"
                name="primaryColor"
                type="color"
                value={settings.primaryColor}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Font Size"
                name="fontSize"
                type="number"
                value={settings.fontSize}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={4}>
          <Typography variant="h6" gutterBottom>Language Settings</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="language-select-label">Language</InputLabel>
                <Select
                  labelId="language-select-label"
                  id="language-select"
                  value={settings.language}
                  label="Language"
                  onChange={handleInputChange}
                  name="language"
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="id">Bahasa Indonesia</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </TabPanel>

        <Divider sx={{ my: 2 }} />
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
          >
            Save Settings
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default Settings;