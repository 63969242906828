import { axiosInstance, API_ENDPOINTS } from '../api/api';

const WAREHOUSE_ENDPOINT = '/api/warehouses/';

export const warehouseService = {
  fetchAllWarehouses: async () => {
    try {
      let allWarehouses = [];
      let nextPage = WAREHOUSE_ENDPOINT;

      while (nextPage) {
        const response = await axiosInstance.get(nextPage);
        allWarehouses = [...allWarehouses, ...response.data.results];
        nextPage = response.data.next;
      }

      return allWarehouses;
    } catch (error) {
      console.error('Error fetching all warehouses:', error);
      throw error;
    }
  },

  createWarehouse: async (warehouseData) => {
    try {
      const response = await axiosInstance.post(WAREHOUSE_ENDPOINT, warehouseData);
      return response.data;
    } catch (error) {
      console.error('Error creating warehouse:', error);
      throw error;
    }
  },

  updateWarehouse: async (id, warehouseData) => {
    try {
      const response = await axiosInstance.put(`${WAREHOUSE_ENDPOINT}${id}/`, warehouseData);
      return response.data;
    } catch (error) {
      console.error('Error updating warehouse:', error);
      throw error;
    }
  },

  deleteWarehouse: async (id) => {
    try {
      await axiosInstance.delete(`${WAREHOUSE_ENDPOINT}${id}/`);
    } catch (error) {
      console.error('Error deleting warehouse:', error);
      throw error;
    }
  },

  fetchWarehouseById: async (id) => {
    try {
      const response = await axiosInstance.get(`${WAREHOUSE_ENDPOINT}${id}/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching warehouse:', error);
      throw error;
    }
  },

  fetchInventoryByWarehouse: async (warehouse_id) => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.inventory,{
        params: {
          warehouse_id,
          page_size: 1000, // Set a large number to get all orders
        },
      });
      console.log("Inventory is: ", response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching inventory:', error);
      throw error;
    }
  },

  fetchInventoryByProduct: async (product_id) => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.inventory,{
        params: {
          product_id,
          page_size: 1000, // Set a large number to get all orders
        },
      });
      console.log("Inventory is: ", response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching inventory:', error);
      throw error;
    }
  },
};