import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Container, Paper, Typography, Button, CircularProgress, Box, Snackbar,
  Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  IconButton, TextField, Autocomplete
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import { inventoryService } from '../services/inventoryService';
import { warehouseService } from '../services/warehouseService';
import { productService } from '../services/productService';
import useDebounce from '../hooks/useDebounce';
import useSessionStorage from '../hooks/useSessionStorage';
// import { useAuth } from '../contexts/AuthContext';

const schema = yup.object().shape({
  transfer_date: yup.date().required('Transfer date is required'),
  from_warehouse: yup.object().nullable().required('From Warehouse is required'),
  to_warehouse: yup.object().nullable().required('To Warehouse is required'),
  transfer_number: yup.string().required('Transfer number is required'),
  items: yup.array().of(
    yup.object().shape({
      product: yup.object().nullable().required('Product is required'),
      quantity: yup.number().positive('Quantity must be positive').integer('Quantity must be a whole number').required('Quantity is required'),
    })
  ).min(1, 'At least one item is required')
});

const WarehouseTransferDetailEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [warehouses, setWarehouses] = useSessionStorage('warehouses', []);
  const [products, setProducts] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, 300);
  const MIN_SEARCH_CHARS = 3;

  const { control, handleSubmit, setValue, watch, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      transfer_date: null,
      from_warehouse: null,
      to_warehouse: null,
      transfer_number: '',
      items: []
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items'
  });

  useEffect(() => {
    fetchInputProducts();
  }, [debouncedInputValue]);

  const fetchInputProducts = async () => {
    if (debouncedInputValue.length >= MIN_SEARCH_CHARS) {
      try {
        const data = await productService.searchProducts(debouncedInputValue);
        const formattedData = data.map(product => ({
          ...product,
          product_name: product.name,
        }));
        setProducts(formattedData);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let warehousesData = warehouses;
        if (warehouses.length === 0) {
          warehousesData = await warehouseService.fetchAllWarehouses();
          setWarehouses(warehousesData);
        }
        const transferData = await inventoryService.getTransferById(id);
        const productsData = transferData.items.map(item => item.product);
        setProducts(productsData);
        setValue('transfer_date', dayjs(transferData.transfer_date));
        setValue('transfer_number', transferData.transfer_number);
        console.log('Transfer data:', transferData);
        const selectedFromWarehouse = warehousesData.find(w => w.id === transferData.from_warehouse);
        setValue('from_warehouse', selectedFromWarehouse || null);
        
        const selectedToWarehouse = warehousesData.find(w => w.id === transferData.to_warehouse);
        setValue('to_warehouse', selectedToWarehouse || null);
        
        const formattedItems = transferData.items.map(item => {
          return {
            product: item.product,
            quantity: item.quantity,
            fromQty: item.from_qty || 0,
            toQty: item.to_qty || 0
          };
        });
        setValue('items', formattedItems);
        console.log('Formatted items:', formattedItems);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [id, setValue]);

  const onSubmit = async (data) => {
    try {
      const updatedTransfer = {
        ...data,
        from_warehouse: data.from_warehouse?.id,
        to_warehouse: data.to_warehouse?.id,
        transfer_date: dayjs(data.transfer_date).format('YYYY-MM-DD'),
        user: 1,
        items: data.items.map(item => ({
          product_id: item.product?.id,
          quantity: item.quantity
        }))
      };
      console.log('Sending updated transfer:', updatedTransfer);
      const response = await inventoryService.updateTransfer(id, updatedTransfer);
      console.log('Update response:', response);
      navigate(`/warehouse/transfer/${id}`);
    } catch (error) {
      console.error('Error updating transfer:', error);
    }
  };

  const handleWarehouseChange = (warehouseType, newValue) => {
    setValue(warehouseType, newValue);
    updateItemQuantities();
  };

  const updateItemQuantities = () => {
    const fromWarehouse = watch('from_warehouse');
    const toWarehouse = watch('to_warehouse');
    const currentItems = watch('items');

    const updatedItems = currentItems.map(item => {
      if (item.product) {
        const productInventory = item.product.inventory || [];
        const fromWarehouseInventory = productInventory.find(inv => inv.warehouse_name === fromWarehouse?.name);
        const toWarehouseInventory = productInventory.find(inv => inv.warehouse_name === toWarehouse?.name);
        
        return {
          ...item,
          fromQty: fromWarehouseInventory ? fromWarehouseInventory.quantity_on_hand : 0,
          toQty: toWarehouseInventory ? toWarehouseInventory.quantity_on_hand : 0,
        };
      }
      return item;
    });

    setValue('items', updatedItems);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="lg">
        <Paper elevation={3} sx={{ p: 3, my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Edit Warehouse Transfer
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="transfer_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      label="Transfer Date"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.transfer_date,
                          helperText: errors.transfer_date?.message
                        }
                      }}
                      disabled
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="transfer_number"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Transfer Number"
                      fullWidth
                      error={!!errors.transfer_number}
                      helperText={errors.transfer_number?.message}
                      disabled
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="from_warehouse"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={warehouses}
                      getOptionLabel={(option) => option?.name || ''}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="From Warehouse"
                          error={!!errors.from_warehouse}
                          helperText={errors.from_warehouse?.message}
                        />
                      )}
                      onChange={(_, data) => handleWarehouseChange('from_warehouse', data)}
                      disabled
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="to_warehouse"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={warehouses}
                      getOptionLabel={(option) => option?.name || ''}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="To Warehouse"
                          error={!!errors.to_warehouse}
                          helperText={errors.to_warehouse?.message}
                        />
                      )}
                      onChange={(_, data) => handleWarehouseChange('to_warehouse', data)}
                      disabled
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 4, mb: 2 }}>
              <Typography variant="h6" component="h2">
                Item Details
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => append({ product: null, quantity: 1, fromQty: 0, toQty: 0 })}
              >
                Add Item
              </Button>
            </Box>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="40%">Item SKU</TableCell>
                    <TableCell width="15%">Quantity to Transfer</TableCell>
                    <TableCell width="15%">From Warehouse Qty</TableCell>
                    <TableCell width="15%">To Warehouse Qty</TableCell>
                    <TableCell width="15%">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((field, index) => (
                    <TableRow key={field.id}>
                      <TableCell>
                        <Controller
                          name={`items.${index}.product`}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              options={products}
                              getOptionLabel={(option) => option ? `${option.sku} - ${option.name}` : ''}
                              // isOptionEqualToValue={(option, value) => option?.id === value?.id}
                              isOptionEqualToValue={(option, value) => 1 === 1}
                              onInputChange={(_, newInputValue) => {
                                setInputValue(newInputValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!errors.items?.[index]?.product}
                                  helperText={errors.items?.[index]?.product?.message}
                                  size="small"
                                />
                              )}
                              onChange={(_, value) => {
                                field.onChange(value);
                                updateItemQuantities();
                              }}
                              size="small"
                              // value={field.product.name || null}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`items.${index}.quantity`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              error={!!errors.items?.[index]?.quantity}
                              helperText={errors.items?.[index]?.quantity?.message}
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          Current: {watch(`items.${index}.fromQty`) || 0}
                        </Typography>
                        <Typography variant="body2">
                          After Transfer: {(watch(`items.${index}.fromQty`) || 0) - (watch(`items.${index}.quantity`) || 0)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          Current: {watch(`items.${index}.toQty`) || 0}
                        </Typography>
                        <Typography variant="body2">
                          After Transfer: {(watch(`items.${index}.toQty`) || 0) + (watch(`items.${index}.quantity`) || 0)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => remove(index)} color="error">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              </TableContainer>

            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Button variant="outlined" onClick={() => navigate(`/warehouse/transfer/${id}`)} sx={{ mr: 1 }}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Update Transfer
                </Button>
              </Box>
            </Box>
          </form>
        </Paper>
        
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          message={snackbar.message}
        />
      </Container>
    </LocalizationProvider>
  );
};

export default WarehouseTransferDetailEdit;