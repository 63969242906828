import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#FFF3E0', // Light orange background
  '&:hover': {
    backgroundColor: '#FFE0B2', // Slightly darker orange on hover
  },
  transition: 'background-color 0.3s',
  boxShadow: theme.shadows[2],
}));

const InfoCard = ({ title, value, subtitle, icon: Icon }) => {
  return (
    <StyledCard>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h4" component="div" color="text.primary">
              {value}
            </Typography>
            {subtitle && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {subtitle}
              </Typography>
            )}
          </Box>
          {Icon && <Icon sx={{ fontSize: 40, color: '#FB8C00' }} />}
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default InfoCard;