import React, { useState } from 'react';
import { 
  Button, 
  Box, 
  Typography,
  Tabs,
  Tab,
  TextField
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { 
  Edit as EditIcon, 
  Delete as DeleteIcon, 
  Visibility as VisibilityIcon 
} from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

const ContactDashboard = () => {
  const [contacts, setContacts] = useState([
    { id: 1, name: 'John Doe', address: '123 Main St', phone: '555-1234', type: 'customer' },
    { id: 2, name: 'Jane Smith', address: '456 Elm St', phone: '555-5678', type: 'supplier' },
    // Add more sample data as needed
  ]);

  const [currentTab, setCurrentTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const handleCreateCustomer = () => {
    // Implement create new customer logic
    console.log('Create new customer');
  };

  const handleCreateSupplier = () => {
    // Implement create new supplier logic
    console.log('Create new supplier');
  };

  const handleView = (id) => {
    // Implement view contact logic
    console.log('View contact', id);
  };

  const handleEdit = (id) => {
    // Implement edit contact logic
    console.log('Edit contact', id);
  };

  const handleDelete = (id) => {
    // Implement delete contact logic
    console.log('Delete contact', id);
  };

  const columns = [
    { field: 'name', headerName: <FormattedMessage id="contact.name" />, flex: 1 },
    { field: 'address', headerName: <FormattedMessage id="contact.address" />, flex: 1 },
    { field: 'phone', headerName: <FormattedMessage id="contact.phone" />, flex: 1 },
    {
      field: 'actions',
      headerName: <FormattedMessage id="contact.actions" />,
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Button
            onClick={() => handleView(params.row.id)}
            startIcon={<VisibilityIcon />}
          >
            <FormattedMessage id="contact.view" />
          </Button>
          <Button
            onClick={() => handleEdit(params.row.id)}
            startIcon={<EditIcon />}
          >
            <FormattedMessage id="contact.edit" />
          </Button>
          <Button
            onClick={() => handleDelete(params.row.id)}
            startIcon={<DeleteIcon />}
            color="error"
          >
            <FormattedMessage id="contact.delete" />
          </Button>
        </Box>
      ),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const filteredContacts = contacts.filter(
    contact => 
      contact.type === (currentTab === 0 ? 'customer' : 'supplier') &&
      (contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
       contact.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
       contact.phone.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        <FormattedMessage id="contact.dashboard.title" />
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateCustomer}
          sx={{ mr: 2 }}
        >
          <FormattedMessage id="contact.new.customer" />
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCreateSupplier}
        >
          <FormattedMessage id="contact.new.supplier" />
        </Button>
      </Box>
      <Tabs value={currentTab} onChange={handleTabChange} sx={{ mb: 2 }}>
        <Tab label={<FormattedMessage id="contact.tab.customers" />} />
        <Tab label={<FormattedMessage id="contact.tab.suppliers" />} />
      </Tabs>
      <TextField
        fullWidth
        variant="outlined"
        label={<FormattedMessage id="contact.search" />}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 2 }}
      />
      <DataGrid
        rows={filteredContacts}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        checkboxSelection
        disableSelectionOnClick
      />
    </Box>
  );
};

export default ContactDashboard;