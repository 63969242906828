import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { AttachMoney, TrendingUp, MoneyOff } from '@mui/icons-material';
import InfoCard from '../components/common/InfoCard';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { useAuth } from '../contexts/AuthContext';

const dummySalesData = [
  { id: 1, sku: 'SKU001', name: 'Product A', quantity: 10, amount: 1000 },
  { id: 2, sku: 'SKU002', name: 'Product B', quantity: 5, amount: 750 },
  { id: 3, sku: 'SKU003', name: 'Product C', quantity: 8, amount: 1200 },
];

const Dashboard = () => {
  useDocumentTitle('Main');
  const { user, login, logout } = useAuth();

  console.log("Users is: ", user);
  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <InfoCard
            title="Total Revenue"
            value="$50,000"
            subtitle="This month"
            icon={AttachMoney}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InfoCard
            title="Profit"
            value="$20,000"
            subtitle="This month"
            icon={TrendingUp}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InfoCard
            title="Expenses"
            value="$30,000"
            subtitle="This month"
            icon={MoneyOff}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;