export default {
    'common.loading': 'Loading...',
    'common.edit': 'Edit',
    'common.delete': 'Delete',
    'common.view': 'View',
    'common.back': 'Back',
    'common.confirm': 'Confirm',
    'common.cancel': 'Cancel',
    'common.search': 'Search',
    'common.save': 'Save',
    'common.filter': 'Filter',
    'common.sort': 'Sort',
    'common.page': 'Page',
    'common.of': 'of',
    'common.actions' : 'Actions',
    'common.noResults': 'No results found',
    'common.error': 'Error: {error}',
  
    'date.today': 'Today',
    'date.yesterday': 'Yesterday',
    'date.tomorrow': 'Tomorrow',
    'date.lastWeek': 'Last Week',
    'date.nextWeek': 'Next Week',
    'date.lastMonth': 'Last Month',
    'date.nextMonth': 'Next Month',
  
    'currency.symbol': 'Rp. ',
    'currency.code': 'IDR',
  
    'notification.success': 'Success',
    'notification.error': 'Error',
    'notification.info': 'Information',
    'notification.warning': 'Warning',
  
    'confirm.delete': 'Are you sure you want to delete this item?',
    'confirm.discard': 'Are you sure you want to discard your changes?',
  
    'a11y.loading': 'Loading content, please wait',
    'a11y.closed': 'Closed',
    'a11y.opened': 'Opened',
    'a11y.selected': 'Selected',
    'a11y.collapsed': 'Collapsed',
    'a11y.expanded': 'Expanded',
  };