import React from 'react';

const csvSamples = {
    products: `sku,name,inventory_account,buy_price,buy_account,sale_price,sale_account,stock_quantity,status,average_price,length,height,width,weight,minimum_stock,description
  PROD001,Widget A,INV-001,10.50,550000,19.99,SAL-001,100,1,15.25,10,5,5,200,20,"High-quality widget for multiple uses"
  PROD002,Gadget B,INV-002,25.00,550000,49.99,SAL-002,50,1,37.50,15,8,8,500,10,"Advanced gadget with smart features"`,
  
    orders: `order_id,customer_name,order_date,product_sku,quantity,unit_price,total_price
  ORD001,John Doe,2023-07-01,PROD001,2,19.99,39.98
  ORD002,Jane Smith,2023-07-02,PROD002,1,49.99,49.99`,
  
    purchasing: `purchase_order_id,supplier_name,order_date,product_sku,quantity,unit_price,total_price
  PO001,Supplier A,2023-06-15,PROD001,50,10.50,525.00
  PO002,Supplier B,2023-06-16,PROD002,25,25.00,625.00`,
  
    expenses: `expense_id,expense_date,category,amount,description
  EXP001,2023-07-05,Office Supplies,150.00,"Printer paper and ink cartridges"
  EXP002,2023-07-06,Utilities,200.00,"Electricity bill for June"`,
  };
  
  export const getCSVSample = (importType) => {
    return csvSamples[importType] || 'No sample available for this import type';
  };
  
  export const availableCSVSamples = Object.keys(csvSamples);