import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography, 
  Box, 
  CircularProgress,
  Link
} from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { axiosFileInstance, API_IMPORT_ENDPOINTS } from '../api/api';
import { getCSVSample } from '../utils/csvSamples';

const GenericImport = ({ importType, title, onClose, onImportComplete }) => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorMessage = 'Import failed: ';
    if (!file) {
      setMessage('Please select a file');
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axiosFileInstance.post(API_IMPORT_ENDPOINTS[importType], formData);
      console.log(`Import successful. Created: ${response.data.created}, Updated: ${response.data.updated}`);
      setMessage(`Import successful. Created: ${response.data.created}, Updated: ${response.data.updated}`);
      onImportComplete({ success: true, data: response.data });
    } catch (error) {
      console.error('Error request:', error.request);
      errorMessage += 'No response received from server';
      console.error('Error message:', error.message);
      console.log(`Import failed: ${error.response?.data?.error || error.message}`);
      setMessage(`Import failed: ${error.response?.data?.error || error.message}`);
      onImportComplete({ success: false, error: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadSample = () => {
    const csvContent = getCSVSample(importType);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `sample_${importType}_import.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{ my: 2 }}>
          <Typography variant="body1" gutterBottom>
            Please upload a CSV file with the correct format. 
            <Link 
              component="button"
              variant="body2"
              onClick={handleDownloadSample}
              sx={{ ml: 1 }}
            >
              Download sample CSV
            </Link>
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <input
              accept=".csv"
              style={{ display: 'none' }}
              id="raised-button-file"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="raised-button-file">
              <Button variant="contained" component="span" startIcon={<CloudUpload />}>
                Choose File
              </Button>
            </label>
            <Typography variant="body2" sx={{ ml: 2 }}>
              {file ? file.name : 'No file chosen'}
            </Typography>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading || !file}
            sx={{ mt: 2 }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Import'}
          </Button>
        </form>
        {message && (
          <Typography 
            variant="body2" 
            sx={{ 
              mt: 2, 
              color: message.includes('successful') ? 'success.main' : 'error.main' 
            }}
          >
            {message}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenericImport;