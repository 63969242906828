import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { accountService } from '../../services/accountService';

const NewAccountForm = ({ open, handleClose, onAccountCreated }) => {
  const [formData, setFormData] = useState({
    name: '',
    type: '',
    initialBalance: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await accountService.addAccount(formData);
      onAccountCreated();
      handleClose();
    } catch (error) {
      console.error('Error creating account:', error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create New Account</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          name="name"
          label="Account Name"
          value={formData.name}
          onChange={handleChange}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Account Type</InputLabel>
          <Select name="type" value={formData.type} onChange={handleChange}>
            <MenuItem value="asset">Asset</MenuItem>
            <MenuItem value="liability">Liability</MenuItem>
            <MenuItem value="equity">Equity</MenuItem>
            <MenuItem value="income">Income</MenuItem>
            <MenuItem value="expense">Expense</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          margin="normal"
          name="initialBalance"
          label="Initial Balance"
          type="number"
          value={formData.initialBalance}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">Create Account</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewAccountForm;