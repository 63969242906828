import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, Paper, Button, TextField } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { orderService } from '../services/orderService';
import { formatCurrency } from '../utils/currencyUtils';
import { format, subDays } from 'date-fns';
import useDebounce from '../hooks/useDebounce';
import InfoCard from "../components/common/InfoCard";
// import { orderService } from "../services/orderService";

const SalesDashboard = () => {
    const navigate = useNavigate();
    const intl = useIntl();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [revenue, setRevenue] = useState(0);
    const [totalOrder, setTotalOrder] = useState(0);
    const debouncedSearchTerm = useDebounce(searchTerm, 300);

    const MIN_SEARCH_CHARS = 3;

    useEffect(() => {
        fetchSearchOrders();
      }, [debouncedSearchTerm]);
    
      const fetchSearchOrders = async () => {
        if (debouncedSearchTerm.length >= MIN_SEARCH_CHARS) {
          try {
            setLoading(true);
            // const data = await productService.fetchAllProducts(includeInactive);
            // const data = await productService.searchProducts(debouncedSearchTerm, page +1, pageSize);
            const data = await orderService.searchOrders(debouncedSearchTerm, 1, 100);
            console.log("Data is ",data);
            setOrders(data.results);
            const totalRevenue = data.results.reduce((sum, order) => sum + parseFloat(order.total), 0);
            // const totalOrder = data.results.length);
            setRevenue(formatCurrency(totalRevenue));
            setTotalOrder(data.results.length);
            // console.log("Revenue is: ",totalRevenue);
          } catch (error) {
            console.error('Error fetching products:', error);
          }finally{
            setLoading(false);
          }
    }};

    const fetchSalesData = useCallback(async () => {
        try {
            setLoading(true);
            
            const today = new Date();
            const yesterday = subDays(today, 1);
            
            // const startDate = format(yesterday, 'yyyy-MM-dd');
            const todayDate = format(today, 'yyyy-MM-dd');
            
            const fetchedOrdersData = await orderService.searchOrders(todayDate, 1, 100);
            console.log("Fetched orders:", fetchedOrdersData.results);
            
            const formattedOrders = fetchedOrdersData.results.map(order => ({
                ...order,
                id: order.id,
                order_date: order.order_date,
                customer_name: order.customer_name, 
                total: formatCurrency(order.total),
                tag: order.tag || 'N/A',
                status: order.status || 'N/A'
            }));
            
            console.log("Formatted orders:", formattedOrders);
            setOrders(formattedOrders);
        } catch (error) {   
            console.error("Error fetching sales data:", error);
            // Handle error (e.g., show error message to user)
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchSalesData();
    }, [fetchSalesData]);

    const columns = [
        { field: 'order_date', headerName: intl.formatMessage({ id: 'sales.grid.date' }), flex: 1, minWidth: 100 },
        { field: 'customer_name', headerName: intl.formatMessage({ id: 'sales.grid.customer' }), flex: 1, minWidth: 150 },
        { field: 'order_number', headerName: intl.formatMessage({ id: 'sales.grid.orderNumber' }), flex: 1, minWidth: 110 },
        { field: 'total', headerName: intl.formatMessage({ id: 'sales.grid.total' }), flex: 1, minWidth: 100 },
        { field: 'tag', headerName: intl.formatMessage({ id: 'sales.grid.tag' }), flex: 1, minWidth: 80 },
        // { field: 'status', headerName: intl.formatMessage({ id: 'sales.grid.status' }), flex: 1, minWidth: 100 },
        {
            field: 'actions',
            headerName: intl.formatMessage({ id: 'sales.grid.actions' }),
            flex: 1,
            minWidth: 160,
            renderCell: (params) => (
                <Box>
                    <Button size="small" onClick={() => handleView(params.row)}>
                        <FormattedMessage id="sales.button.view" />
                    </Button>
                    <Button size="small" onClick={() => handleDelete(params.row)}>
                        <FormattedMessage id="sales.button.delete" />
                    </Button>
                </Box>
            ),
        }
    ];

    const handleView = (row) => {
        navigate(`/sales/${row.id}`);
    };

    const handleDelete = async (row) => {
        if (window.confirm(intl.formatMessage({ id: "sales.confirm.delete" }, { orderNumber: row.order_number }))) {
            try {
                await orderService.deleteOrder(row.id);
                fetchSalesData();
            } catch (error) {
                console.error("Error deleting sale:", error);
                // Handle error (e.g., show error message to user)
            }
        }
    };

    const handleAddNew = () => {
        navigate('/sales/new');
    };

    const handleExport = async () => {
        try {
            const blob = await orderService.exportOrders();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'sales_export.csv');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("Error exporting sales data:", error);
            // Handle error (e.g., show error message to user)
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        // console.log("Handle search change: ");
    }

    return (
        <Box sx={{ height: '100%', width: '100%', p: 2 }}>
                <Typography variant="h5" gutterBottom>
                    <FormattedMessage id="sales.dashboard.title" />
                </Typography>
            {/* <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', gap: 1, mb: 2 }}>
                <InfoCard title="Revenue" value={revenue} />
                <InfoCard title="Total Order" value={totalOrder} />
            </Box> */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', gap: 1, mb: 2 }}>
                <Button variant="contained" size="small" onClick={handleAddNew}>
                    <FormattedMessage id="sales.button.addNew" />
                </Button>
                {/* <Button variant="outlined" size="small" onClick={handleExport}>
                    <FormattedMessage id="sales.button.export" />
                </Button> */}
                {/* <Button variant="outlined" size="small" onClick={handleImport}>
                    <FormattedMessage id="sales.button.import" />
                </Button> */}
            </Box>

            <TextField
                label="Search orders"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchTerm}
                onChange={handleSearchChange}
            />

            <Paper elevation={3} sx={{ p: 2 }}>
                <DataGrid
                    rows={orders}
                    columns={columns}
                    pageSize={50}
                    rowsPerPageOptions={[25, 50, 100]}
                    disableSelectionOnClick
                    loading={loading}
                />
            </Paper>
        </Box>
    );
};

export default SalesDashboard;