// src/services/purchasingService.js

import { axiosInstance, API_ENDPOINTS } from '../api/api';
import { formatCurrency } from '../utils/currencyUtils';

export const purchasingService = {
  async fetchAllPurchaseOrders() {
    try {
      console.log('Fetching all purchase orders');
      const response = await axiosInstance.get(API_ENDPOINTS.purchaseOrders, {
        params: {
          page_size: 1000,
        },
      });
      console.log('Received purchase orders:', response.data);
      return response.data.results;
    } catch (error) {
      console.error('Error fetching purchase orders:', error);
      throw error;
    }
  },

  async fetchPurchaseOrderDetails(id) {
    try {
      console.log(`Fetching purchase order details for ID: ${id}`);
      const response = await axiosInstance.get(`${API_ENDPOINTS.purchaseOrders}${id}/`);
      console.log('Raw API response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching purchase order details:', error);
      throw error;
    }
  },

  async createPurchaseOrder(purchaseOrderData) {
    try {
      const response = await axiosInstance.post(API_ENDPOINTS.purchaseOrders, purchaseOrderData);
      return {
        ...response.data,
        total: formatCurrency(response.data.total)
      };
    } catch (error) {
      console.error('Error creating purchase order:', error);
      throw error;
    }
  },

  async updatePurchaseOrder(id, purchaseOrderData) {
    try {
      const response = await axiosInstance.put(`${API_ENDPOINTS.purchaseOrders}${id}/`, purchaseOrderData);
      return {
        ...response.data,
        total: formatCurrency(response.data.total)
      };
    } catch (error) {
      console.error('Error updating purchase order:', error);
      throw error;
    }
  },

  async deletePurchaseOrder(id) {
    try {
      await axiosInstance.delete(`${API_ENDPOINTS.purchaseOrders}${id}/`);
    } catch (error) {
      console.error('Error deleting purchase order:', error);
      throw error;
    }
  },

  async fetchPurchaseOrdersByDateRange(startDate, endDate, page = 1, pageSize = 100) {
    try {
      console.log(`Fetching purchase orders for date range: ${startDate} to ${endDate}`);
      const response = await axiosInstance.get(API_ENDPOINTS.purchaseOrders, {
        params: {
          start_date: startDate,
          end_date: endDate,
          page,
          page_size: pageSize,
        },
      });
      console.log('Received purchase orders:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching purchase orders by date range:', error);
      throw error;
    }
  },

  async searchPO(searchQuery) {
    try {
      const response = await axiosInstance.get(`${API_ENDPOINTS.purchaseOrders}`, {
        params: {
          search: searchQuery
        }
      });
      console.log('Received search POs:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error searching purchase orders:', error);
      throw error;
    }
  },
};