// src/components/ExpenseDashboard/ExpenseForm.js
import React from 'react';
import { 
  TextField, 
  Autocomplete, 
  DialogContent, 
  DialogActions, 
  Button, 
  CircularProgress 
} from '@mui/material';
import { NumericFormat } from 'react-number-format';

const ExpenseForm = ({ 
  expense, 
  setExpense, 
  categories, 
  accounts, 
  handleClose, 
  handleSubmit, 
  loading, 
  editMode 
}) => {
  return (
    <>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Date"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={expense.date}
          onChange={(e) => setExpense({ ...expense, date: e.target.value })}
        />
        <Autocomplete
            options={categories}
            getOptionLabel={(option) => option?.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} label="Category" margin="dense" />}
            value={expense.category}
            onChange={(event, newValue) => {
            setExpense({ ...expense, category: newValue });
            }}
            fullWidth
        />
        <TextField
          margin="dense"
          label="Description"
          fullWidth
          value={expense.description}
          onChange={(e) => setExpense({ ...expense, description: e.target.value })}
        />
        <NumericFormat
          customInput={TextField}
          thousandSeparator="."
          decimalSeparator=","
          prefix="Rp "
          allowNegative={false}
          decimalScale={0}
          margin="dense"
          label="Amount"
          fullWidth
          value={expense.amount}
          onValueChange={(values) => {
            setExpense({ ...expense, amount: values.value });
          }}
        />
        <Autocomplete
            options={accounts}
            getOptionLabel={(option) => option?.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} label="Account" margin="dense" />}
            value={expense.account}
            onChange={(event, newValue) => {
            setExpense({ ...expense, account: newValue });
            }}
            fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : (editMode ? 'Update' : 'Add')}
        </Button>
      </DialogActions>
    </>
  );
};

export default ExpenseForm;