// src/services/expenseService.js

import { axiosInstance, API_ENDPOINTS } from '../api/api';

export const expenseService = {
  async fetchExpenses(page = 1, pageSize = 10) {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.expense, {
        params: {
          page,
          page_size: pageSize,
        },
      });
      return {
        results: response.data.results,
        totalCount: response.data.count,
        nextPage: response.data.next,
        previousPage: response.data.previous,
      };
    } catch (error) {
      console.error('Error fetching expenses:', error);
      throw error;
    }
  },

  async addExpense(expenseData) {
    try {
      const response = await axiosInstance.post(API_ENDPOINTS.expense, expenseData);
      return response.data;
    } catch (error) {
      console.error('Error adding expense:', error);
      throw error;
    }
  },

  async updateExpense(id, expenseData) {
    try {
      const response = await axiosInstance.put(`${API_ENDPOINTS.expense}${id}/`, expenseData);
      return response.data;
    } catch (error) {
      console.error('Error updating expense:', error);
      throw error;
    }
  },

  async deleteExpense(id) {
    try {
      await axiosInstance.delete(`${API_ENDPOINTS.expense}${id}/`);
    } catch (error) {
      console.error('Error deleting expense:', error);
      throw error;
    }
  },

  async fetchAllPaginatedData(endpoint) {
    let allData = [];
    let nextPage = endpoint;

    while (nextPage) {
      try {
        const response = await axiosInstance.get(nextPage);
        allData = [...allData, ...response.data.results];
        nextPage = response.data.next;
      } catch (error) {
        console.error(`Error fetching data from ${endpoint}:`, error);
        throw error;
      }
    }

    return allData;
  },

  async fetchCategories() {
    try {
      return await this.fetchAllPaginatedData(API_ENDPOINTS.expensecat);
    } catch (error) {
      console.error('Error fetching all categories:', error);
      throw error;
    }
  },

  async fetchAccounts() {
    try {
      return await this.fetchAllPaginatedData(API_ENDPOINTS.accounts);
    } catch (error) {
      console.error('Error fetching all accounts:', error);
      throw error;
    }
  },
  
};