import React, { useState, useEffect } from 'react';
import { 
  Button, 
  Box, 
  Typography,
  IconButton,
  Tooltip,
  TextField,
  Switch,
  FormControlLabel
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { Add, FileUpload, FileDownload, Visibility, Edit, Delete } from '@mui/icons-material';
import GenericImport from '../components/GenericImport';
import { productService } from '../services/productService';
import { formatCurrency } from '../utils/currencyUtils';
import useDebounce from '../hooks/useDebounce';

const ProductDashboard = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSuperuser, setIsSuperuser] = useState(true);
  const [showImport, setShowImport] = useState(false);
  const [searchTerm, setSearchTerm] = useState('Puzzle knob');
  const [includeInactive, setIncludeInactive] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const MIN_SEARCH_CHARS = 3;

  useEffect(() => {
    fetchProducts();
  }, [debouncedSearchTerm,page,pageSize,includeInactive]);

  const fetchProducts = async () => {
    if (debouncedSearchTerm.length >= MIN_SEARCH_CHARS) {
      try {
        setLoading(true);
        // const data = await productService.fetchAllProducts(includeInactive);
        const data = await productService.searchProducts(debouncedSearchTerm, page +1, pageSize);
        // console.log("Data is ",data);
        const formattedData = data.map(product => ({
          ...product,
          formattedSalePrice: formatCurrency(product.sale_price),
          // formattedBuyPrice: formatCurrency(product.buy_price)
        }));
        setProducts(formattedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    }
  };

  const handleAddNew = () => {
    navigate('/product/new');
  };

  const handleView = (id) => {
    navigate(`/product/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`/product/edit/${id}`);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await productService.deleteProduct(id);
        fetchProducts();
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  };

  const handleExport = () => {
    console.log('Export functionality to be implemented');
  };

  const handleImportComplete = (result) => {
    console.log('Import completed:', result);
    if (result.success) {
      fetchProducts();
    }
    setShowImport(false);
  };

  const handleSearchChange = (event) => {
    console.log("Masuk search");
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on new search
  };

  const columns = [
    { field: 'sku', headerName: 'SKU', width: 120 },
    { field: 'name', headerName: 'Name', width: 300 },
    { field: 'quantity', headerName: 'Quantity', width: 100, type: 'number' },
    { field: 'formattedSalePrice', headerName: 'Sale Price', width: 120 },
    { field: 'note', headerName: 'Note', width: 200 },
    // { field: 'formattedBuyPrice', headerName: 'Buy Price', width: 120 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <IconButton onClick={() => handleView(params.row.id)} size="small">
              <Visibility />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title="Edit">
            <IconButton onClick={() => handleEdit(params.row.id)} size="small">
              <Edit />
            </IconButton>
          </Tooltip>
          {isSuperuser && (
            <Tooltip title="Delete">
              <IconButton onClick={() => handleDelete(params.row.id)} size="small">
                <Delete />
              </IconButton>
            </Tooltip>
          )} */}
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ height: '100%', width: '100%', p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Product Dashboard
      </Typography>
      
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Button variant="outlined" startIcon={<Add />} onClick={handleAddNew} sx={{ mr: 1 }}>
            Add New Product
          </Button>
          <Button variant="outlined" startIcon={<FileDownload />} onClick={handleExport} sx={{ mr: 1 }}>
            Export
          </Button>
          <Button variant="outlined" startIcon={<FileUpload />} onClick={() => setShowImport(true)}>
            Import
          </Button>
        </Box>
        <FormControlLabel
          control={
            <Switch
              checked={includeInactive}
              onChange={(e) => setIncludeInactive(e.target.checked)}
            />
          }
          label="Include Inactive Products"
        />
      </Box>
      
      <TextField
        label="Search products"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={products}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          loading={loading}
        />
      </Box>

      {showImport && (
        <GenericImport
          importType="products"
          title="Import Products"
          onClose={() => setShowImport(false)}
          onImportComplete={handleImportComplete}
        />
      )}
    </Box>
  );
};

export default ProductDashboard;