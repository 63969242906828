import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StorefrontIcon from '@mui/icons-material/Storefront';
import InventoryIcon from '@mui/icons-material/Inventory';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContactsIcon from '@mui/icons-material/Contacts';
import SettingsIcon from '@mui/icons-material/Settings';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../contexts/AuthContext';

import { Box, Divider } from '@mui/material';

const SideBar = ({ isOpen, handleDrawerToggle, onLogout }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user,logout } = useAuth();
  // const drawerItems = [
  //   { text: 'Sales', icon: <ShoppingCartIcon />, link: '/sales', roles: ['employee', 'spv'] },
  //   { text: 'Purchasing', icon: <StorefrontIcon />, link: '/purchase', roles: ['spv'] },
  //   { text: 'Account', icon: <AccountBalanceIcon />, link: '/account', roles: ['spv'] },
  //   { text: 'Product', icon: <InventoryIcon />, link: '/product', roles: ['employee', 'spv'] },
  //   { text: 'Warehouse', icon: <WarehouseIcon />, link: '/warehouse', roles: ['employee', 'spv'] },
  // ];

  const drawerItems = [
    // { text: 'Dashboard', icon: <DashboardIcon />, link: '/' },
    
    { text: 'Sales', icon: <ShoppingCartIcon />, link: '/sales', roles: ['ADMIN','SPV','ACC','EMP']  },
    { text: 'Purchasing', icon: <StorefrontIcon />, link: '/purchase', roles: ['ADMIN','ACC'] },
    { text: 'Account', icon: <AccountBalanceIcon />, link: '/account', roles: ['ADMIN','ACC'] },
    { text: 'Product', icon: <InventoryIcon />, link: '/product',roles: ['ADMIN','SPV','ACC','EMP'] },
    { text: 'Expense', icon: <ReceiptIcon />, link: '/expense',roles: ['ADMIN','ACC'] },
    // { text: 'Contact', icon: <ContactsIcon />, link: '/contact' },
    { text: 'Warehouse', icon: <WarehouseIcon />, link: '/warehouse',roles: ['ADMIN','EMP','SPV','ACC'] },
    // { text: 'Asset', icon: <BusinessCenterIcon />, link: '/asset' },
    // { text: 'Reporting', icon: <AssessmentIcon />, link: '/reporting' },
    // { text: 'Settings', icon: <SettingsIcon />, link: '/settings' }
  ];

  const filteredDrawerItems = drawerItems.filter(item => item.roles.includes(user.role));

  const handleLogout = () => {
    // onLogout();
    logout();
    navigate('/login');  // Redirect to login page after logout
  };

  return (
    <Drawer
      variant="persistent"
      open={isOpen}
      onClose={handleDrawerToggle}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '4', p: 2, flexDirection: 'row' }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
      </Box>
      <Divider />
      
      <List disablePadding>
        {filteredDrawerItems.map((item) => (
          <ListItemButton
            component={Link}
            to={item.link}
            key={item.text}
            selected={location.pathname.startsWith(item.link)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
        <Divider />
        <ListItemButton onClick={handleLogout}>
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </Drawer>
  );
};

SideBar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default SideBar;