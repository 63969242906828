import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Paper } from '@mui/material';
import { AttachMoney, TrendingUp, MoneyOff } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import InfoCard from '../components/common/InfoCard';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { productService } from '../services/productService';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import { customerService } from '../services/customerService';
import { formatCurrency } from '../utils/currencyUtils';
import useSessionStorage from '../hooks/useSessionStorage';

const Monitor = () => {
  useDocumentTitle('Monitor');
  const [customers, setCustomers] = useSessionStorage('customers', []);
  const [productSalesData, setProductSalesData] = useState([]);
  const [revenue, setRevenue] = useState(0);
  const [profit, setProfit] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dateFrom, setDateFrom] = useState(dayjs().subtract(1, 'day'));
  const [dateTo, setDateTo] = useState(dayjs());
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const fromDate = dateFrom.format('YYYY-MM-DD');
        const toDate = dateTo.format('YYYY-MM-DD');
        
        if(customers.length < 0 ){
          const response = await customerService.fetchAllCustomers();
          setCustomers(response.results);
        }
        const customerIds = selectedCustomers.map(customer => customer.id);
        const data = await productService.fetchProductSalesData(fromDate, toDate, customerIds);
        setProductSalesData(data);
        setRevenue(data.reduce((acc, item) => acc + item.revenue, 0));
        setProfit(data.reduce((acc, item) => acc + item.total_profit, 0));
      } catch (error) {
        console.error('Error fetching product sales data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [dateFrom, dateTo, selectedCustomers]);
  
  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Monitor
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <InfoCard
            title="Total Revenue"
            value={formatCurrency(revenue)}
            icon={AttachMoney}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InfoCard
            title="Total Profit"
            value={formatCurrency(profit)}
            icon={MoneyOff}
          />
        </Grid>
      </Grid>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
          <Grid item>
            <DatePicker
              label="From Date"
              value={dateFrom}
              onChange={(newValue) => setDateFrom(newValue)}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="To Date"
              value={dateTo}
              onChange={(newValue) => setDateTo(newValue)}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="customer-select"
              options={customers}
              getOptionLabel={(option) => option.full_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Customers"
                  placeholder="Customers"
                />
              )}
              onChange={(event, newValue) => {
                setSelectedCustomers(newValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>

      <Typography variant="h6" gutterBottom>
        Product Sales Data
      </Typography>

      <DataGrid
        rows={productSalesData}
        columns={[
          { field: 'sku', headerName: 'SKU', flex: 1 },
          { field: 'name', headerName: 'Product Name', flex: 2 },
          { field: 'quantity', headerName: 'Quantity Sold', type: 'number', flex: 1 },
          {
            field: 'revenue',
            headerName: 'Total Revenue',
            type: 'number',
            flex: 1,

          },
          {
            field: 'cost',
            headerName: 'Total Cost',
            type: 'number',
            flex: 1,

          },
          {
            field: 'total_profit',
            headerName: 'Total Profit',
            type: 'number',
            flex: 1,
          },
          {
            field: 'margin',
            headerName: 'Margin',
            type: 'number',
            flex: 1,
          },
        ]}
        pageSize={20}
        rowsPerPageOptions={[20, 50, 100]}
        getRowId={(row) => row.sku}
      />

    </Box>
  );
};

export default Monitor;
