export default {
    'purchase.dashboard.title': 'Purchase Dashboard',
    'purchase.button.addNew': 'Add New Purchase',
    'purchase.button.export': 'Export Purchases',
    'purchase.button.import': 'Import Purchases',
    'purchase.grid.date': 'Date',
    'purchase.grid.orderNumber': 'Purchase #',
    'purchase.grid.total': 'Total',
    'purchase.grid.status': 'Status',
    'purchase.confirm.delete': 'Are you sure you want to delete purchase order {orderNumber}?',
    'purchase.edit.title': 'Edit Purchase Order',
    'purchase.edit.orderDetails': 'Order Details',
    'purchase.edit.orderItems': 'Order Items',
    'purchase.field.orderNumber': 'Order Number',
    'purchase.field.orderDate': 'Order Date',
    'purchase.field.supplier': 'Supplier',
    'purchase.field.warehouse': 'Warehouse',
    'purchase.field.status': 'Status',
    'purchase.field.memo': 'Memo',
    'purchase.field.product': 'Product',
    'purchase.field.quantity': 'Quantity',
    'purchase.field.price': 'Price',
    'purchase.field.total': 'Total',
    'purchase.field.actions': 'Actions',
    'purchase.button.addItem': 'Add Item',
    
    'purchase.error.orderNumber.required': 'Order number is required',
    'purchase.error.supplier.required': 'Supplier is required',
    'purchase.error.orderDate.required': 'Order date is required',
    'purchase.error.warehouse.required': 'Warehouse is required',
    'purchase.error.status.required': 'Status is required',
    'purchase.error.product.required': 'Product is required',
    'purchase.error.quantity.positive': 'Quantity must be positive',
    'purchase.error.quantity.required': 'Quantity is required',
    'purchase.error.price.positive': 'Price must be positive',
    'purchase.error.price.required': 'Price is required',
    'purchase.error.items.min': 'At least one item is required',
    'purchase.error.orderNotFound': 'Order not found',
    'purchase.success.orderUpdated': 'Order updated successfully',
    'purchase.error.updateFailed': 'Failed to update order. Please try again.',
  };