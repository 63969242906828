import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  TextField,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  Typography,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import { warehouseService } from '../services/warehouseService';
import { productService } from '../services/productService';
import { inventoryService } from '../services/inventoryService';
import { commonService } from '../services/commonService';
import useDebounce from '../hooks/useDebounce';
import useSessionStorage from '../hooks/useSessionStorage';

const WarehouseTransferForm = () => {
  const [transferDate, setTransferDate] = useState(dayjs());
  const [fromWarehouse, setFromWarehouse] = useState(null);
  const [toWarehouse, setToWarehouse] = useState(null);
  const [transferNo, setTransferNo] = useState('');
  const [transferItems, setTransferItems] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [products, setProducts] = useState([]);
  const [inventories, setInventories] = useState('');
  const [loading, setLoading] = useState();
  const [warehouses, setWarehouses] = useSessionStorage('warehouses', []);
  const debouncedInputValue = useDebounce(inputValue, 300);
  const MIN_SEARCH_CHARS = 3;

  const navigate = useNavigate();

  useEffect( () => {
    fetchInputProducts();
    },[debouncedInputValue]);

    const fetchInputProducts = async () => {
      if (debouncedInputValue.length >= MIN_SEARCH_CHARS) {
        try {
          const data = await productService.searchProducts(debouncedInputValue);
          const formattedData = data.map(product => ({
            ...product
          }));
          setProducts(formattedData);

          console.log("Formatted data is: ",formattedData);
        } catch (error) {
          console.error('Error fetching products:', error);
        }
      }
    };


  useEffect(() => {
      const fetchData = async () => {
        try {
          // Fetch transfer number
          const nextNumber = await commonService.getNextTransactionNumber('STOCK_TRANSFER');
          setTransferNo(nextNumber);
    
          // Fetch warehouses
          let warehousesData = warehouses;

          const inventories = await warehouseService.fetchInventoryByWarehouse(1);
          console.log("Inventories", inventories);

          if (warehouses.length === 0) {
            warehousesData = await warehouseService.fetchAllWarehouses();
            setWarehouses(warehousesData);
          }

        } catch (error) {
          console.error('Error fetching data:', error);
          setSnackbar({ open: true, message: 'Error fetching data', severity: 'error' });
        }
      };
    
      fetchData();
    }, []);

  const handleAddItem = () => {
    setTransferItems([...transferItems, { product: null, quantity: 0, fromQty: 0, toQty: 0 }]);
  };

  const handleRemoveItem = (index) => {
    const newItems = transferItems.filter((_, i) => i !== index);
    setTransferItems(newItems);
  };

  const updateItemQuantities = (items) => {
    return items.map(item => {
      if (item.product) {
        const productInventory = item.product.inventory || [];
        const fromWarehouseInventory = productInventory.find(inv => inv.warehouse_name === fromWarehouse?.name);
        const toWarehouseInventory = productInventory.find(inv => inv.warehouse_name === toWarehouse?.name);
        
        return {
          ...item,
          fromQty: fromWarehouseInventory ? fromWarehouseInventory.quantity_on_hand : 0,
          toQty: toWarehouseInventory ? toWarehouseInventory.quantity_on_hand : 0,
        };
      }
      return item;
    });
  };

  const handleItemChange = (index, field, value) => {
    const newItems = [...transferItems];
    newItems[index][field] = value;

    if (field === 'product') {
      newItems[index] = updateItemQuantities([newItems[index]])[0];
    }

    setTransferItems(newItems);
  };

  const handleWarehouseChange = (warehouseType, newValue) => {
    if (warehouseType === 'from') {
      setFromWarehouse(newValue);
    } else {
      setToWarehouse(newValue);
    }
    
    setTransferItems(prevItems => updateItemQuantities(prevItems));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setConfirmDialogOpen(true);
  };

  const handleConfirmSubmit = async () => {
    console.log('From Warehouse:', fromWarehouse);
    console.log('To Warehouse:', toWarehouse);
    setConfirmDialogOpen(false);
    if (!fromWarehouse || !toWarehouse) {
      setSnackbar({ open: true, message: 'From Warehouse and To Warehouse are required', severity: 'error' });
      return;
    }
    
    const transferData = {
      transfer_date: transferDate.format('YYYY-MM-DD'),
      from_warehouse: fromWarehouse.id,
      to_warehouse: toWarehouse.id,
      transfer_number: transferNo,
      user: 1,
      items: transferItems.map(item => ({
        product_id: item.product?.id,
        quantity: item.quantity
      }))
    };
    
    try {
      const response = await inventoryService.createTransfer(transferData);
      await commonService.incrementTransactionNumber('STOCK_TRANSFER');
      setSnackbar({ open: true, message: 'Transfer created successfully', severity: 'success' });
      navigate('/warehouse/');
      // Reset form
      setTransferDate(dayjs());
      setFromWarehouse(null);
      setToWarehouse(null);
      const nextNumber = await commonService.getNextTransactionNumber('STOCK_TRANSFER');
      setTransferNo(nextNumber);
      setTransferItems([]);
    } catch (error) {
      let errorMessage = 'An error occurred while creating the transfer';
      if (error.response && error.response.data) {
        errorMessage = JSON.stringify(error.response.data);
      }
      setSnackbar({ open: true, message: errorMessage, severity: 'error' });
    }
  };

  const availableToWarehouses = useMemo(() => {
    return Array.isArray(warehouses) ? warehouses.filter(w => w.id !== fromWarehouse?.id) : [];
  }, [warehouses, fromWarehouse]);

  const availableFromWarehouses = useMemo(() => {
    return Array.isArray(warehouses) ? warehouses.filter(w => w.id !== toWarehouse?.id) : [];
  }, [warehouses, toWarehouse]);

  const availableProducts = useMemo(() => {
    return products.filter(p => !transferItems.some(item => item.product?.id === p.id));
  }, [products, transferItems]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form onSubmit={handleSubmit}>
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <DatePicker
            label="Transfer Date"
            value={transferDate}
            onChange={(newValue) => setTransferDate(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
          />
          <Autocomplete
            options={availableFromWarehouses}
            getOptionLabel={(option) => option.name}
            value={fromWarehouse}
            onChange={(_, newValue) => handleWarehouseChange('from', newValue)}
            renderInput={(params) => <TextField {...params} label="From Warehouse" fullWidth margin="normal" />}
            isOptionEqualToValue={(option, value) => "A" === "A"}
          />
          <Autocomplete
            options={availableToWarehouses}
            getOptionLabel={(option) => option.name}
            value={toWarehouse}
            onChange={(_, newValue) => handleWarehouseChange('to', newValue)}
            renderInput={(params) => <TextField {...params} label="To Warehouse" fullWidth margin="normal" />}
            isOptionEqualToValue={(option, value) => "A" === "A"}
          />
          <TextField
            label="Transfer Number"
            value={transferNo}
            onChange={(e) => setTransferNo(e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />
        </Paper>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="50%">SKU - Item Name</TableCell>
                <TableCell width="15%">Quantity to Transfer</TableCell>
                <TableCell width="15%">From Warehouse Qty</TableCell>
                <TableCell width="15%">To Warehouse Qty</TableCell>
                <TableCell width="5%">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transferItems.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Autocomplete
                      options={availableProducts}
                      getOptionLabel={(option) => `${option.sku} - ${option.name}`}
                      value={item.product}
                      onInputChange={(_, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      onChange={(_, newValue) => handleItemChange(index, 'product', newValue)}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                      isOptionEqualToValue={(option, value) => "A" === "A"}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={item.quantity}
                      onChange={(e) => handleItemChange(index, 'quantity', parseInt(e.target.value, 10))}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">Current: {item.fromQty}</Typography>
                    <Typography variant="body2">After Transfer: {item.fromQty - item.quantity}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">Current: {item.toQty}</Typography>
                    <Typography variant="body2">After Transfer: {item.toQty + item.quantity}</Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleRemoveItem(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Button
          startIcon={<AddIcon />}
          onClick={handleAddItem}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Add Item
        </Button>

        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, ml: 2 }}>
          Submit Transfer
        </Button>

        <Dialog
          open={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
        >
          <DialogTitle>Confirm Transfer</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to submit this warehouse transfer?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleConfirmSubmit} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={6000} 
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </form>
    </LocalizationProvider>
  );
};

export default WarehouseTransferForm;